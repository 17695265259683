import Vue from "vue";
import { Color } from "vuetify/lib/util/colors";
import Vuex from "vuex";
import { readThemeColorSetting, setNewThemeColor } from "../plugins/vuetify";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        isNavigationBarMini: true,
        appVersion: process.env.PACKAGE_VERSION || "0",
        selectedThemeColor: readThemeColorSetting()
    },
    mutations: {
        setNavigationBarMini(state, val: boolean) {
            state.isNavigationBarMini = val;
        },
        setSelectedThemeColor(state, val: Color) {
            setNewThemeColor(val);
            state.selectedThemeColor = val;
        },
    },
    actions: {
    },
    modules: {
    },
});

export default store;
