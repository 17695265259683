
import MatrixTreeComponent from "./matrix-tree-component.vue";
import Vue from "vue";
import { PropValidator } from "vue/types/options";
import { LevelMatrix } from "easyclick-common/model/levelmatrix-model";

export default Vue.extend({
    name: "LevelPickerComponent",
    components: {
        matrixTreeComponent: MatrixTreeComponent,
    },
    props: {
        value: { type: Object } as PropValidator<LevelMatrix>,
        valuetext: String,
        disabled: Boolean
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data: () => ({
        level: null,
        menu2: false,
        modal2: false,
    }),
    computed: {

    },
    methods: {
        valueFromTree(val: LevelMatrix) {
            this.$emit("input", val);
            this.menu2 = false;
        },
    },
});
