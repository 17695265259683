
import { EnumTrivalent } from "easyclick-common/model/enumeration";
import Vue from "vue";
import { DataTableHeader } from "vuetify";
import { UiTicketDetail } from "../model/ticketdetail";
import { i18n } from "../plugins/i18n";
import LevelPickerComponent from "./level-picker-component.vue";
import type { UiLevelMatrix } from "../model/ticketdetail";
import { formatDuration } from "../helper/helper";
import { Ticket } from "easyclick-common/model/ticket-model";
import { PropValidator } from "vue/types/options";
import VueI18n from "vue-i18n";


type RuleTypeList = ((v: string) => boolean | string | VueI18n.TranslateResult)[];

type DataType = { oldItem: UiTicketDetail, editedIndex: number, };
type PropsType = { value: Ticket, disabled: boolean, isAdminMode: boolean, isNewTicket: boolean, };
type ComputedType = {
    ticketDetails: UiTicketDetail[],
    editedItem: UiTicketDetail,
    headers: DataTableHeader[],
    canDetailQuantityActive: boolean,
    canCommentActive: boolean,
    isCommentRequired: boolean,
    showDeleteIcon: boolean,
    commentRules: RuleTypeList,
    commentRules2: string,
    levelMatrix: UiLevelMatrix,
};
type MethodType = {
    formatDuration: (value?: number) => string,
    addRow: () => void,
    editItem: (item: UiTicketDetail) => void,
    close: () => void,
    cancel: () => void,
    save: () => void,
    deleteItem: (item: UiTicketDetail) => void,
    setNewLevelMatrix: (lm: UiLevelMatrix) => void,
    levelMatrixText: (item: UiTicketDetail) => string,
};

export type TicketDetailComponentType = Vue & DataType & MethodType & ComputedType & PropsType;

export default Vue.extend<DataType, MethodType, ComputedType, PropsType>({
    name: "TicketDetailComponent",
    props: {
        value: { type: Object } as PropValidator<Ticket>,
        disabled: Boolean,
        isAdminMode: Boolean,
        isNewTicket: Boolean
    },
    components: {
        levelPickerComponent: LevelPickerComponent
    },
    data: () => ({
        oldItem: new UiTicketDetail(),
        editedIndex: -1,
    }),
    computed: {
        editedItem() {
            return this.ticketDetails[this.editedIndex] ?? new UiTicketDetail();
        },
        ticketDetails() {
            return (this.value.ticketDetails ?? []) as UiTicketDetail[];
        },
        headers() {
            if (this.isAdminMode) {
                return [
                    { text: i18n.t("site.general.actions"), value: "action", sortable: false },
                    { text: i18n.t("object.ticketDetail.levelMatrix"), value: "levelMatrix", sortable: false },
                    { text: i18n.t("object.ticketDetail.quantity"), value: "quantity", sortable: false },
                    { text: i18n.t("object.ticketDetail.comment"), value: "comment", sort3able: false },
                    !this.isAdminMode ? undefined : { text: this.$i18n.t("object.ticketDetail.customField1short"), value: "customField1" },//TODO: configuration for using customFields in ticketdetails
                    !this.isAdminMode ? undefined : { text: this.$i18n.t("object.ticketDetail.customField2short"), value: "customField2" },
                    !this.isAdminMode ? undefined : { text: this.$i18n.t("object.ticketDetail.customField3short"), value: "customField3" },
                    { text: i18n.t("object.ticketDetail.duration"), value: "durationMSecs", sortable: false },
                    !this.isAdminMode ? undefined : { text: i18n.t("object.ticket.useForReporting"), value: "useForReporting", sortable: false },
                ].filter((x) => x) as DataTableHeader[];
            }
            else {
                return [
                    { text: i18n.t("site.general.actions"), value: "action", sortable: false },
                    { text: i18n.t("object.ticketDetail.levelMatrix"), value: "levelMatrix", sortable: false },
                    { text: i18n.t("object.ticketDetail.quantity"), value: "quantity", sortable: false },
                    { text: i18n.t("object.ticketDetail.comment"), value: "comment", sort3able: false },
                    { text: i18n.t("object.ticketDetail.duration"), value: "durationMSecs", sortable: false },
                    !this.isAdminMode ? undefined : { text: i18n.t("object.ticket.useForReporting"), value: "useForReporting", sortable: false },
                ].filter((x) => x) as DataTableHeader[];
            }
        },
        isCommentRequired() {
            return this.levelMatrix.isTicketDetailTextRequired === EnumTrivalent.Mandatory;
        },
        canCommentActive(): boolean {
            return this.levelMatrix.isTicketDetailTextRequired !== EnumTrivalent.Disabled;
        },
        showDeleteIcon(): boolean {
            return ((this.isNewTicket) || (this.isAdminMode));
        },
        canDetailQuantityActive(): boolean {
            return this.levelMatrix.isDetailQuantityRequired !== EnumTrivalent.Disabled;
        },
        commentRules(): RuleTypeList {
            if (this.isCommentRequired) {
                return [
                    (v: string) => !!v || i18n.t("site.component.ticketDetailsComponent.commentRequired"),
                ];
            }
            return [];
        },
        commentRules2(): string {
            if (this.isCommentRequired && !this.editedItem.comment) {
                return "mdi-asterisk";
            }
            return "";
        },
        levelMatrix: {
            get() {
                return this.editedItem.levelMatrix;
            },
            set(value) {
                this.editedItem.levelMatrix = value;
            }
        },
    },
    watch: {
        value(newVal, oldVal) {
            if (newVal != oldVal) {
                this.close();
            }
        }
    },
    methods: {
        formatDuration(value?: number) {
            return formatDuration(value, "millisecond", "mm:ss");
        },
        levelMatrixText(item: UiTicketDetail) {
            if (item?.levelMatrix?.getPath) {
                return item.levelMatrix.getPath.slice(-5).join(" -> ");
            }
            return "";
        },
        addRow() {
            const addObj = new UiTicketDetail();
            this.ticketDetails.unshift(addObj);
            if (this.editedIndex < 0) {
                this.editItem(addObj);
            }
        },
        editItem(item: UiTicketDetail) {
            //FIXME: If editedItem is set to other and also changed, no info for the user and new data are removed.
            this.editedIndex = this.ticketDetails.indexOf(item) ?? -1;
            this.oldItem = Object.assign({}, item);
            this.$forceUpdate();
        },
        close() {
            this.editedIndex = -1;
        },
        cancel() {
            Object.assign(this.ticketDetails[this.editedIndex], this.oldItem);
            this.close();
        },
        save() {
            if (!this.levelMatrix.ignoreTime) {
                this.editedItem.durationMSecs = this.value.activeMSecs - this.ticketDetails.reduce((sum, b) => sum + b.durationMSecs, 0) + this.editedItem.durationMSecs;
            }
            this.$emit("update");
            this.close();
        },
        deleteItem(item: UiTicketDetail) {
            const index = this.ticketDetails.indexOf(item);
            if (index >= 0) {
                this.ticketDetails.splice(index, 1);
            }
        },
        setNewLevelMatrix(lm: UiLevelMatrix) {
            if (!this.disabled
                && this.editedItem) {
                this.levelMatrix = lm;
                this.$forceUpdate();
                if (this.$refs.commentDetails) {
                    // eslint-disable-next-line jsdoc/require-jsdoc
                    (this.$refs.commentDetails as Element & { validate(): void, }).validate();
                }
            }
            if (!this.levelMatrix.ignoreTime) {
                this.editedItem.durationMSecs = this.value.activeMSecs - this.ticketDetails.reduce((sum, b) => sum + b.durationMSecs, 0) + this.editedItem.durationMSecs;
            }
            this.$emit("update");// new entry in ticketdetail-list should be active without additional click on save-icon
            this.close();
        }
    }
});
