
import { DataTableHeader } from "vuetify";
import { VDataTable } from "vuetify/lib";
import xlsx from "json-as-xlsx";
import moment from "moment";
import Vue from "vue";

export type HeaderEditType = "icon" | "checkbox" | "select" | "switch" | "textfield" | "radio";

export type HeaderType = DataTableHeader & { formatter?: (value: unknown) => string, type?: HeaderEditType, bind: unknown, listeners: unknown, };

export type HeaderTypeList = HeaderType[];

type PropsType = {
    appName: string,
    tableName?: string,
    noDeleteAction?: boolean,
    isExportAllowed?: boolean,
    isUserList?: boolean,
};
type DataType = { searchInt: string, };
type MethodsType = {
    exportToExcel: () => void,
    calculcateTableName: () => string,
    setUp: (item: unknown, propName: string, value: unknown) => void,
};
type ComputedType = {
    computedSearch: string,
    formatterHeaders: HeaderTypeList,
};

export default Vue.extend({ mixins: [VDataTable] }).extend<DataType, MethodsType, ComputedType, PropsType>({
    name: "TableComponent",
    props: {
        tableName: {
            type: String,
            default: undefined
        },
        appName: {
            type: String,
            required: true,
        },
        noDeleteAction: {
            type: Boolean,
            default: false,
        },
        isExportAllowed: {
            type: Boolean,
            default: false,
        },
        isUserList: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            searchInt: "",
        };
    },
    computed: {
        computedSearch() {
            return this.search ?? this.searchInt;
        },
        formatterHeaders() {
            return (this.headers as HeaderTypeList).filter((header) =>
                !!header.formatter || !!header.type);
        }
    },
    methods: {
        exportToExcel() {
            const data = [{
                sheetName: this.calculcateTableName(),
                columns: (this.headers as HeaderType[]).map((x: DataTableHeader) => ({ label: x.text, value: x.value })),
                content: this.items,
            }];
            let settings = {
                fileName: this.calculcateTableName() + moment().format("_YYYYMMDD_HHmmss"), // Name of the spreadsheet
                extraLength: 3, //
                writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
            };
            xlsx(data, settings);
        },
        calculcateTableName(): string {
            return this.tableName ?? this.appName;
        },
        setUp(item: any, propName: string, value: unknown) {
            item[propName] = value;
        },
    }
});
