
import Vue from "vue";

export default Vue.extend({
    name: "DeleteDialogComponent",
    props: {
        value: { type: Boolean },
    },
    watch: {
        dialogDelete(val) {
            val || this.cancel();
        },
    },
    methods: {
        execute() {
            this.$emit("execute");
        },
        cancel() {
            this.$emit("cancel");
        },
    }
});
