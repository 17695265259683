
import Vue from "vue";
import { ROUTE_DEFAULT } from "../plugins/router";
import userModule from "../store/modules/user-module";

export default Vue.extend({
    components: {},
    name: "TokenView",
    mounted() {
        userModule.saveToken(this.$route.params.token);
        this.$router.push({
            name: ROUTE_DEFAULT,
        },
        );
    },
});
