import { EasyCalcLevelMatrix } from "easyclick-common/model/calclevelmatrix-model";
import { UserLevelMatrix } from "easyclick-common/model/levelmatrix-model";
import { UserLevelVal } from "easyclick-common/model/levelval-model";
import { TicketDetail } from "easyclick-common/model/ticketdetail-model";


export type UiLevelMatrix = EasyCalcLevelMatrix<UserLevelMatrix, UserLevelVal>;
/**
 *
 */
export class UiTicketDetail extends TicketDetail {
    levelMatrix: UiLevelMatrix;
    constructor() {
        super();
        this.levelMatrix = new EasyCalcLevelMatrix();
    }
}
