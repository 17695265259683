
import moment from "moment";
import Vue from "vue";
import { VTextField } from "vuetify/lib/components";

export default Vue.extend({ mixins: [VTextField] }).extend({
    name: "DateTimeComponent",
    methods: {
        toFormattedDate(value: Date) {
            return moment(value).format("L");
        },
        calculatedTimeForDatePicker(value: Date) {
            return moment(value).format("yyyy-MM-DD");
        },
        setNewDate(date: string) {
            this.$emit("input", moment(date, "yyyy-MM-DD"));
        }
    }
});
