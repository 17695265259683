
import Vue from "vue";
import UserModule from "../store/modules/user-module";
import UserInfoComponent from "./userinfo-component.vue";
import { RouteRecordPublic, RouteConfig, RouteRecord } from "vue-router";
import { getLocaleToUrlLocale, toggleBetweenLanguages } from "../plugins/i18n";
import { colorMenuTileBackground, storageKeyTheme, colorList } from "../plugins/vuetify";
import cloneDeep from "lodash.clonedeep";

export default Vue.extend({
    name: "NavigationComponent",
    components: {
        userInfoComponent: UserInfoComponent,
    },
    data: () => ({
        colorMenuOpen: false,
        showUserInfo: false
    }),
    computed: {
        expandOnHover() {
            // Disable Expand-On-Hover if either certain routes are active, or the color menu is opened (otherwise the colormenu will be closed once you enter it!)
            return !(this.colorMenuOpen || this.$route.meta?.nav.showNavBarExpanded);
        },
        isNavigationMini: {
            get(): boolean {
                // If Expand-On-Hover is disabled (see above), this also needs to return false!
                if (!this.expandOnHover) {
                    return false;
                }

                return this.$store.state.isNavigationBarMini;
            },
            set(val: boolean) {
                if (val)
                    this.showUserInfo = false;
                this.$store.commit("setNavigationBarMini", val);
            },
        },
        loggedIn() {
            return UserModule.isLoggedIn;
        },
        routes() {
            const flatRouteList = (this.$router.getRoutes() ?? []).filter((x) => calculateNavVisible(x as RouteRecord));
            const treeRouteList = this.$router.options.routes ?? [] as RouteConfig[];
            const tree = treeRouteList.map((route) => filterTreeVisible(route, flatRouteList)).filter((route) => !!route) as RouteConfig[];
            return tree.flatMap((x) => x.meta?.nav ? x : x.children);
        },
        darkModeActive() {
            return this.$vuetify.theme.dark;
        },
        backgroundImage() {
            return "application_background.svg";
        },
        logoImage() {
            return "application_logo.png";
        },
        backgroundColor() {
            return colorMenuTileBackground;
        },
        locateUrl() {
            return getLocaleToUrlLocale(this.$i18n.locale);
        },
        colorGrid() {
            var gridOfColors = chunkArray(colorList, 4);
            return gridOfColors;
        },
        selectedThemeColor: {
            get() {
                return this.$store.state.selectedThemeColor;
            },
            set(newColor) {
                this.$store.commit("setSelectedThemeColor", newColor);
            },
        },
    },
    methods: {
        toggleDarkmode() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            localStorage.setItem(storageKeyTheme, this.$vuetify.theme.dark.toString());
        },
        toggleLanguage() {
            const locale = getLocaleToUrlLocale(toggleBetweenLanguages());
            const to = this.$router.resolve({ params: { locale } });
            this.$router.push(to.location);
        },
        navDrawerTransitionEnd() {
            //console.log(event);
            // This helps to delay the visibility of the user Info when the navbar starts to open, so it won't get scrambled.
            if (!this.isNavigationMini)
                this.showUserInfo = true;
        }
    },
});

/**
 *
 * @param arr
 * @param size
 */
function chunkArray<T>(arr: T[], size: number): T[][] {
    return arr.length > size
        ? [arr.slice(0, size), ...chunkArray(arr.slice(size), size)]
        : [arr];
}

/**
 * Find out if entries should be visible or not.
 *
 * @param route
 */
function calculateNavVisible(route: RouteRecord): boolean {
    if (!route.meta?.nav) {
        return false;
    }
    else if (route.meta?.doLogIn !== undefined) {
        return !route.meta.doLogIn || !UserModule.isLoggedIn;
    }
    else if (route.meta?.requiresAdminRights !== undefined) {
        return !route.meta.requiresAdminRights || UserModule.isUserAdmin;
    }
    else if (route.meta?.requiresUserLogin !== undefined) {
        return !route.meta.requiresUserLogin || UserModule.isLoggedIn;
    }
    else if (route.parent) {
        return calculateNavVisible(route.parent);
    }

    return false;
}

/**
 *
 * @param route
 * @param flatRouteList
 * @param rootPath
 */
function filterTreeVisible(route: RouteConfig, flatRouteList: RouteRecordPublic[], rootPath = ""): RouteConfig | undefined {
    const path = rootPath + (!route.path.startsWith("/") ? "/" : "") + route.path;
    if (flatRouteList.some((flatRoute) => flatRoute.path.startsWith(path))) {
        const obj = cloneDeep(route);
        if (route.children && route.children.length > 0) {
            obj.children = [];
            obj.children = [...route.children.map((childRoute) => filterTreeVisible(childRoute, flatRouteList, path)).filter((x) => x !== undefined && (x.children || !!x.meta?.nav)) as RouteConfig[]];
        }
        return obj;
    }
    return;
    //treeRouteList.filter((route) => flatRouteList.some((r2) => route.path.startsWith(r2.path)))
}

