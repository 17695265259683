
import Vue from "vue";
import ReportingComponent from "../components/reporting-component.vue";
import userModule from "../store/modules/user-module";

export default Vue.extend({
    name: "ReportingView",
    components: {
        reportingComponent: ReportingComponent,
    },
    methods: {
        isAdmin() {
            return userModule.isUserAdmin;
        }
    }
});
