import moment from "moment";
import "moment-duration-format";

export type TextValArr = { text: string, value: string, }[];

export let daysOfWeekLocale: TextValArr = [];

export const nbsp = "\xa0";
export const arrowSign = "\u279C"; // ➜

/**
 *
 * @param token
 */
export function parseJwtForUserId(token: string): string {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(atob(base64).split("").map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(""));

    return JSON.parse(jsonPayload).id;
}

/*
// Helper function to stringify the values of an Object
export function toString(value: unknown): string {
    if (value === null || typeof value === "undefined") {
        return "";
    } else if (value instanceof Object) {
        return Object.keys(value)
            .sort()
            .map(key => toString(value[key]))
            .join(" ");
    } else {
        return String(value);
    }
}
*/

/**
 *
 * @param a
 * @param b
 */
export function sortStr(a: string, b: string): number {
    const nameA = a.toUpperCase(); // ignore upper and lowercase
    const nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    // names must be equal
    return 0;
}

/**
 *
 * @param mac
 */
export function isMacAddressValid(mac: string): boolean {
    const regex = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;
    const macFilled = !!mac;
    return macFilled && regex.test(mac);
}

/**
 *
 */
export function initLocale(): void {
    const locale = /*window.navigator.userLanguage ||*/ window.navigator.language; //"en";
    moment.locale(locale);
    //console.log("Current locale:" + moment.locale());
    daysOfWeekLocale = getDaysOfWeekLocale();
}

/**
 *
 */
function getDaysOfWeekLocale(): TextValArr {
    const res = [];
    const firstDayOfWeek = moment.localeData().firstDayOfWeek();
    for (let index = firstDayOfWeek; index < firstDayOfWeek + 7; index++) {
        const indexMod = index % 7;
        res.push({ text: moment.weekdaysShort(false, indexMod), value: `${indexMod}` });
    }

    //console.log(res);
    return res;
}

/**
 *
 */
export class JwtHelper {
    private static _urlBase64Decode(str: string) {
        let output = str.replace(/-/g, "+").replace(/_/g, "/");
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += "==";
                break;
            case 3:
                output += "=";
                break;
            default:
                // tslint:disable-next-line:no-string-throw
                throw "Illegal base64url string!";
        }
        return decodeURIComponent((<any>window).escape(window.atob(output)));
    }

    public static decodeToken(token = ""): unknown {
        if (token === null || token === "") { return { "upn": "" }; }
        const parts = token.split(".");
        if (parts.length !== 3) {

            throw new Error("JWT must have 3 parts");
        }
        const decoded = this._urlBase64Decode(parts[1]);
        if (!decoded) {
            throw new Error("Cannot decode the token");
        }
        return JSON.parse(decoded);
    }
}

/**
 *
 * @param value
 * @param unit
 * @param format
 * @param settings
 */
export function formatDuration(value?: number, unit?: moment.unitOfTime.DurationConstructor, format = "h:m:ss" as string | moment.TemplateFunction, settings?: moment.DurationFormatSettings) {
    return moment.duration(value, unit).format(format, Object.assign({ trim: false, useGrouping: false }, settings));
}
