
import Vue from "vue";
import { LoginMethodType } from "easyclick-common/model/serverinfo-model";
import PasswordChangeComponent from "../components/passwordchange-component.vue";
import { i18n } from "../plugins/i18n";
import ServerInfoModule from "../store/modules/serverinfo-module";
import UserModule from "../store/modules/user-module";

export default Vue.extend({
    name: "SettingsView",
    computed: {
        userSettings() {
            return UserModule.user.settings;
        },
        locale() {
            return this.$i18n.locale;
        },
        passwordChangeAvailable() {
            return ServerInfoModule.serverInfo.loginMethods.find((x) => x.type === LoginMethodType.Intern) !== undefined;
        }
    },
    components: {
        passwordChangeComponent: PasswordChangeComponent,
    },
    methods: {
        submit() {
            UserModule.updateUserSettings({
                ...(UserModule.user.settings?.quickModeEnabled ? { quickModeEnabled: UserModule.user.settings?.quickModeEnabled } : { quickModeEnabled: false }),
                ...(UserModule.user.settings?.silentNotification ? { silentNotification: UserModule.user.settings?.silentNotification } : { silentNotification: false }),
                ...(UserModule.user.settings?.showEndPointIDs ? { showEndPointIDs: UserModule.user.settings?.showEndPointIDs } : { showEndPointIDs: false }),
                ...(UserModule.user.settings?.showParentNodeActionButton ? { showParentNodeActionButton: UserModule.user.settings?.showParentNodeActionButton } : { showParentNodeActionButton: false }),
            }
            )
                .then(() => {
                    Vue.$toast.success(i18n.t("site.general.settingsSavedSuccessfully").toString());
                    return;
                })
                .catch(() => {
                    Vue.$toast.error(i18n.t("site.general.settingsSavingFailed").toString());
                });
        },
    },
});
