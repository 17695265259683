

import Vue from "vue";
import { VTreeview } from "vuetify/lib";

export default Vue.extend(({ mixins: [VTreeview] })).extend({
    name: "TreeComponent",
    props: {
        msg: String,
        showHeadline: { type: Boolean, default: true },
        showCircularProgress: { type: Boolean, default: true },
    },
    data: () => ({
        searchInternal: null,
        caseSensitive: false,
        selection: undefined,
    }),
    computed: {
        filterInternal() {
            if (this.filter) {
                return this.filter;
            }

            return this.caseSensitive ? (item: { [x: string]: string | string[], }, search: string, textKey: string | number) => item[textKey].indexOf(search) > -1
                : undefined;
        },
        treeIsLoading() {
            return this.showCircularProgress && ((this.items as []).length == 0);
        },
    },
    methods: {
        collapseTreeview() {
            if (this.$refs.treeview) {
                (this.$refs.treeview as unknown as { updateAll: (openAll: boolean) => void, }).updateAll(false);
            }
            this.$emit("collapseTreeview");
        },
        openPreopenedNodes() {
            this.$emit("openPreopenedNodes");
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        getItemIcon(_: boolean) {
            /// function is for finding the correct typescript definition of Vue.extend
        },
    },
    watch: {
        caseSensitive(newValue: boolean) {
            this.$emit("update:caseSensitive", newValue);
        }
    }
}
);

