//import axios, { ComposedError } from "@/helper/index";

import { plainToInstance } from "class-transformer";
import { LoginMethod, LoginMethodList } from "easyclick-common/model/serverinfo-model";
import { TokenResponse, LoginUser } from "easyclick-common/model/user-model";
import { LOGIN_METHODS_ROUTE, USER_LOGIN_ROUTE } from "easyclick-common/routes";
import axios, { ComposedError } from "../helper/axios";

/**
 *
 */
class LoginService {
    static getLoginMethods(): Promise<LoginMethodList> {
        return axios.post(LOGIN_METHODS_ROUTE)
            .then((res) => plainToInstance(LoginMethod, res.data as []));
    }

    // Login
    static login(user: LoginUser): Promise<TokenResponse> {
        return axios.post(USER_LOGIN_ROUTE, user)
            .then((res: { status: number, data: TokenResponse, }) => {
                if (res.status === 201
                    && res.data.token) {
                    // handle success
                    return res.data;
                } else {
                    throw false;
                }
            })
            .catch((error: ComposedError) => {
                // do not handle it globally because it will throw
                // an error toast on failed login
                throw error;
            });
    }
}

export default LoginService;
