
import Vue from "vue";
import UserModule from "../store/modules/user-module";
import { appName } from "easyclick-common/config";

export default Vue.extend({
    name: "AppBarComponent",
    computed: {
        leftHeadline() {
            return this.$store.state.appVersion + " - " + this.$route.meta?.nav?.title() ?? "";
        },
        rightHeadline() {
            return UserModule.userDisplayName;
        },
    },
    data: () => ({
        appName: appName,
    }),
});
