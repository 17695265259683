

import Vue from "vue";
import { getAllSubChildren } from "../helper/treetools";

import LevelMatrixModule, { TreeNodeUserEasyClick, TreeNodeUserEasyClickList } from "../store/modules/levelmatrix-module";
import UserModule from "../store/modules/user-module";
import TreeComponent from "./base/tree-component.vue";

export default Vue.extend({
    name: "MatrixTreeComponent",
    components: {
        treeComponent: TreeComponent,
    },
    props: {
        msg: String,
        showHeadline: { type: Boolean, default: true },
    },
    data: () => ({
        search: null,
        caseSensitive: false,
        selection: [] as TreeNodeUserEasyClickList,
    }),
    computed: {
        openedTreeNodes: {
            get() {
                return LevelMatrixModule.userOpenedNodesVar;
            },
            set(newEntries: TreeNodeUserEasyClickList) {
                console.log("set openedTreeNodes  " + newEntries.length /*+ newEntries.map((e) => e.name)*/);
                if (newEntries.length > 0) {
                    LevelMatrixModule.actionSetUserOpenedNodes(newEntries);
                } else {
                    //console.log("set openedTreeNodes is empty!");
                }
            }
        },
        filter() {
            return ((item: TreeNodeUserEasyClick, search: string) => (this.caseSensitive ? item.name.includes(search) : item.name.toLowerCase().includes(search.toLowerCase()))
                || (item.levelMatrix?.id ?? 0).toString().includes(search)
            );
        },
        items() {
            return LevelMatrixModule.levelMatrixTree;
        },
        showEndPointIDs() {
            return UserModule.user.settings.showEndPointIDs;
        },
        showParentNodeActionButton() {
            return UserModule.user.settings.showParentNodeActionButton;
        },
    },
    methods: {
        updateSelectedValue(value: TreeNodeUserEasyClickList) {
            console.log("start updateSelectedValue" + value);
            if (value.length > 0) {
                this.$emit("input", value[0].levelMatrix);
            }
            this.selection.replace(value);
        },
        updateSelectedEntry(value: TreeNodeUserEasyClickList) {
            console.log("start updateSelectedValue" + value);
        },
        updateOpened(openedNodes: TreeNodeUserEasyClickList) {
            console.log("start updateOpened " + openedNodes);
            //LevelMatrixModule.saveOpenedNodes(openedNodes || []);
        },
        updateCaseSensitive(caseSensitive: boolean) {
            this.caseSensitive = caseSensitive;
        },
        getItemIcon(item: TreeNodeUserEasyClick, open: boolean) {
            //console.log(this.selection);
            if (item.isEndPoint) {
                return (this.selection.indexOf(item) >= 0)
                    ? "mdi-checkbox-marked-circle"
                    : "mdi-checkbox-blank-circle-outline";
            } else {
                return open
                    ? "mdi-folder-open"
                    : "mdi-folder";
            }
        },
        openPreopenedNodes() {
            console.log("start openPreopenedNodes");
            LevelMatrixModule.resetUserOpenedNodesToDefault();
        },
        collapseTreeview() {
            LevelMatrixModule.closeAllOpenedNodes();
            console.log("collapseTreeview");
        },
        expandChildren(item: TreeNodeUserEasyClick) {
            //console.log("start expandChildren " + item.name + " " + item.id + " " + item.children?.length);
            const subChildren = getAllSubChildren(item);
            LevelMatrixModule.addUserOpenedNodes(subChildren);
        },

    },
});

