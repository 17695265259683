import VueRouter, { NavigationGuardNext, Route } from "vue-router";
import Vue from "vue";
import LoginView from "../views/login-view.vue";
import LogoutDialogView from "../views/logoutdialog-view.vue";
import SettingsView from "../views/settings-view.vue";
import AboutView from "../views/about-view.vue";
import MainComponent from "../components/main-component.vue";
import NavigationComponent from "../components/navigation-component.vue";
import RouterViewComponent from "../components/base/routerview-component.vue";
import AppBarComponent from "../components/appbar-component.vue";
import TicketOverviewView from "../views/ticketoverview-view.vue";
import TokenView from "../views/token-view.vue";
import TicketView from "../views/ticket-view.vue";
import ReportingView from "../views/reporting-view.vue";

import { defaultLanguage, getBrowserMainLanguage, getLocaleToUrlLocale, getUrlLocaleToLocale, i18n, loadLanguageAsync } from "./i18n";
import { catClient, catHelper } from "../helper/logging";
import userModule from "../store/modules/user-module";
import { appName } from "easyclick-common/config";
import { EnumLanguage } from "easyclick-common/model/enumeration";

//change page title for each route.
//titles are configures in routes.js
//otherwise DEFAULT_TITLE will be set
const DEFAULT_TITLE = appName;
const titlePrefix = DEFAULT_TITLE + " - ";

export const ROUTE_HOME = "home";
export const ROUTE_LOGIN = "login";
export const ROUTE_LOGOUT = "logout";
export const ROUTE_TICKET = "ticket";
export const ROUTE_TICKETOVERVIEW = "ticketoverview";
export const ROUTE_REPORTING = "reporting";
export const ROUTE_SETTINGS = "settings";
export const ROUTE_ADMIN = "admin";

export const ROUTE_MATRIX = "matrix";
export const ROUTE_LEVEL_VALUE = "levelvalue";
export const ROUTE_USER_LIST = "userlist";

export const ROUTE_NAME_ADMIN_MATRIX = ROUTE_ADMIN + "_" + ROUTE_MATRIX;
export const ROUTE_NAME_ADMIN_TICKET = ROUTE_ADMIN + "_" + ROUTE_TICKET;
export const ROUTE_NAME_ADMIN_LEVEL_VALUE = ROUTE_ADMIN + "_" + ROUTE_LEVEL_VALUE;
export const ROUTE_NAME_ADMIN_USER_LIST = ROUTE_ADMIN + "_" + ROUTE_USER_LIST;

export const ROUTE_USER = "user";

export const ROUTE_APPTREE = "apptree";

export const ROUTE_ABOUT = "about";

export const ROUTE_DEFAULT = ROUTE_TICKET;

const permissionDenied = "Keine Berechtigung";

Vue.use(VueRouter);

export const router = new VueRouter({
    routes: [
        {
            path: "/token/:token",
            component: TokenView,
            meta: {
                useNoLocaleCheck: true,
            },
        },
        {
            path: "/:locale",
            components: {
                default: MainComponent,
                navigation: NavigationComponent,
                appbar: AppBarComponent
            },
            children: [
                {
                    path: "", // Needs to be empty.
                    name: ROUTE_HOME,
                    redirect: {
                        name: ROUTE_DEFAULT,
                    },
                },
                {
                    path: ROUTE_LOGIN,
                    name: ROUTE_LOGIN,
                    component: LoginView,
                    meta: {
                        doLogIn: true,
                        nav: {
                            icon: "mdi-login-variant",
                            title: () => i18n.t("site.login"),
                            showNavBarExpanded: true
                        },
                    },
                },
                {
                    path: ROUTE_TICKET,
                    name: ROUTE_TICKET,
                    component: TicketView,
                    meta: {
                        requiresUserLogin: true,
                        nav: {
                            icon: "mdi-file-tree-outline",
                            title: () => i18n.t("site.ticketEntry"),
                        }
                    }
                },
                {
                    path: ROUTE_TICKETOVERVIEW,
                    name: ROUTE_TICKETOVERVIEW,
                    component: TicketOverviewView,
                    meta: {
                        requiresUserLogin: true,
                        nav: {
                            icon: "mdi-ticket-confirmation-outline",
                            title: () => i18n.t("site.ticketUserOverview")
                        }
                    }
                },
                {
                    path: ROUTE_REPORTING,
                    name: ROUTE_REPORTING,
                    component: ReportingView,
                    meta: {
                        requiresUserLogin: true,
                        requiresAdminRights: true,
                        nav: {
                            icon: "mdi-chart-bar",
                            title: () => userModule.isUserAdmin ? i18n.t("site.reporting") : permissionDenied,
                        }
                    }
                },
                {
                    path: ROUTE_SETTINGS,
                    name: ROUTE_SETTINGS,
                    component: SettingsView,
                    meta: {
                        requiresUserLogin: true,
                        nav: {
                            icon: "mdi-cog-outline",
                            title: () => i18n.t("site.settings"),
                        }
                    }
                },
                {
                    path: ROUTE_ADMIN,
                    name: ROUTE_ADMIN,
                    component: RouterViewComponent,
                    meta: {
                        requiresUserLogin: true,
                        requiresAdminRights: true,
                        nav: {
                            icon: "mdi-shield-account",
                            title: () => userModule.isUserAdmin ? i18n.t("site.administration") : permissionDenied,
                        }
                    },
                    children: [
                        {
                            path: ROUTE_LEVEL_VALUE,
                            name: ROUTE_NAME_ADMIN_LEVEL_VALUE,
                            component: () => import(/* webpackChunkName: "admin" */ "../views/admin/levelvalue-view.vue"),
                            meta: {
                                nav: {
                                    icon: "mdi-file-tree-outline",
                                    title: () => i18n.t("site.adminlevelvalue"),
                                },
                            }
                        },
                        {
                            path: ROUTE_MATRIX,
                            name: ROUTE_NAME_ADMIN_MATRIX,
                            component: () => import(/* webpackChunkName: "admin" */ "../views/admin/matrix-view.vue"),
                            meta: {
                                nav: {
                                    icon: "mdi-file-tree-outline",
                                    title: () => i18n.t("site.adminmatrix"),
                                },
                            }
                        },
                        {
                            path: ROUTE_TICKET,
                            name: ROUTE_NAME_ADMIN_TICKET,
                            component: () => import(/* webpackChunkName: "admin" */ "../views/admin/ticket-view-admin.vue"),
                            meta: {
                                nav: {
                                    icon: "mdi-ticket-confirmation-outline",
                                    title: () => i18n.t("site.ticketAdminOverview"),
                                },
                            }
                        },
                        {
                            path: ROUTE_USER_LIST,
                            name: ROUTE_NAME_ADMIN_USER_LIST,
                            component: () => import(/* webpackChunkName: "admin" */ "../views/admin/userlist-view.vue"),
                            meta: {
                                nav: {
                                    icon: "mdi-account-group",
                                    title: () => i18n.t("site.component.userList.userList"),
                                },
                            }
                        }
                    ]
                },
                {
                    path: ROUTE_ABOUT,
                    name: ROUTE_ABOUT,
                    component: AboutView,
                    meta: {
                        requiresUserLogin: false,
                        nav: {
                            icon: "mdi-information-outline",
                            title: () => i18n.t("site.about"),
                        },
                    },
                },
                {
                    path: ROUTE_LOGOUT,
                    name: ROUTE_LOGOUT,
                    component: LogoutDialogView,
                    meta: {
                        requiresUserLogin: true,
                        nav: {
                            icon: "mdi-logout-variant",
                            title: () => i18n.t("site.logout"),
                            showNavBarExpanded: true
                        },
                    },
                },
                {
                    path: "*",
                },
            ],
        },
    ],
    mode: "history",
});

// This is the first thing that is loaded.
// That is why we load the language here, when not yet loaded/changed.
router.beforeEach((to, from, next) => {
    const localeFromRoute = to.params.locale ?? getLocaleToUrlLocale(getBrowserMainLanguage());
    const locale = getUrlLocaleToLocale(localeFromRoute) ?? defaultLanguage;

    if (i18n.locale !== locale) {
        loadLanguageAsync(locale)
            .then(() => executeRouterBeforeEach(locale, to, from, localeFromRoute, next))
            .catch((error) => catClient.error("Error before execute a route", error));
    } else {
        executeRouterBeforeEach(locale, to, from, localeFromRoute, next);
    }
});

router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        // if title is not set use title from nav.title / DEFAULT_TITLE
        document.title = to.meta?.title ?? (to.meta?.nav?.title() ? titlePrefix + to.meta?.nav?.title() : DEFAULT_TITLE);
    });
});

/**
 * @param locale
 * @param to
 * @param from
 * @param localeFromRoute
 * @param next
 */
function executeRouterBeforeEach(locale: EnumLanguage, to: Route, from: Route, localeFromRoute: string, next: NavigationGuardNext<Vue>): void {
    // except token route from Locale check
    if (!to.meta?.useNoLocaleCheck
        && getLocaleToUrlLocale(locale) !== to.params.locale) {
        if (to.params.locale !== undefined) {
            Vue.$toast.error(i18n.t("site.general.languageNotAvailable", [localeFromRoute]));
        }
        next({ name: to.name ?? ROUTE_DEFAULT, params: { locale: getLocaleToUrlLocale(locale) } });
    }
    else if (to.matched.some((record) => record.meta.doLogIn)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (userModule.isLoggedIn) {
            next({
                name: ROUTE_DEFAULT,
                params: to.params,
            });
        }
    } else if (to.matched.some((record) => record.meta.requiresUserLogin)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!userModule.isLoggedIn) {
            next({
                name: ROUTE_LOGIN,
                params: to.params,
            });
        }
    } else if (to.matched.some((record) => record.meta.requiresAdminRights)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!userModule.isUserAdmin) {
            next({
                name: from.name ?? ROUTE_DEFAULT,
                params: to.params,
            });
        }
    } else {
        // Check if route to match to nothing
        // or if it match it has no redirect and no components definition
        // (because of locale parent, parents (first level) must be ignored)
        if (to.matched.length == 0
            || to.matched.some((x) => x.parent !== undefined
                && x.components.default === undefined
                && x.redirect === undefined)) {
            catHelper.error(`Route ${to.path} not found?!`, null);
            Vue.$toast.error(i18n.t("site.general.pageNotAvailable", [to.params.pathMatch ?? to.path]));
            next({ name: ROUTE_DEFAULT });
        }
    }

    next();
}

/**
 *
 */
export function isDefaultLanguageInRoute(): boolean {
    return router.currentRoute.params.locale == getLocaleToUrlLocale(defaultLanguage);
}
