
import Vue from "vue";
import UserModule from "../store/modules/user-module";
import { catClient } from "../helper/logging";

export default Vue.extend({
    name: "UserInfoComponent",
    data: () => ({
        loading: false,
    }),
    computed: {
        user() {
            return UserModule.user;
        },
        userDisplayName() {
            return UserModule.userDisplayName;
        },
        loggedIn() {
            return UserModule.isLoggedIn;
        },
        locale() {
            return this.$i18n.locale;
        },
    },
    created() {
        this.updateUser();
    },
    methods: {
        updateUser() {
            if (!this.loading) {
                this.loading = true;

                //catVue.debug("updateUserAndCompany, set loading to true.");
                UserModule.updateUser()
                    .catch((error) => catClient.error("userChangeHandler", error))
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
    watch: {
        loggedIn: {
            handler(newValue: boolean, oldValue: boolean) {
                if (!newValue) {
                    this.loading = false;
                    //catVue.debug("loggedIn !newValue, set loading to false.");
                }

                if (newValue !== oldValue) {
                    this.updateUser();
                }
            },
            immediate: true,
        },
        locale: {
            handler(newValue, oldValue) {
                if (newValue !== oldValue
                    && this.loggedIn
                    && oldValue !== undefined) {
                    this.updateUser();

                }
            },
            immediate: true,
        },
    },
});
