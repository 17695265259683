
import Vue from "vue";
import "@fontsource/roboto/latin.css";
import { localStorageVersionUpdate } from "../config";
import { appName } from "easyclick-common/config";
import ServerInfoModule from "../store/modules/serverinfo-module";
import { storageKeyTheme } from "../plugins/vuetify";
import { getLoadedLanguages } from "../plugins/i18n";

export default Vue.extend({
    name: appName.replace(" ", ""),
    computed: {
        appName() {
            return appName;
        },
        isLanguageLoading() {
            return getLoadedLanguages().length == 0;
        }
    },
    mounted() {
        ServerInfoModule.retrieveServerInfo();
        const value = localStorage.getItem(localStorageVersionUpdate);
        if (value) {
            this.$toast.info(this.$i18n.t("site.updateInfo"));
            localStorage.removeItem(localStorageVersionUpdate);
        }
        this.$vuetify.theme.dark = ((localStorage.getItem(storageKeyTheme) ?? "true") === "true");
    },
});

