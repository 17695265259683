
import Vue from "vue";
import moment from "moment";
import TimerComponent from "./base/timer-component.vue";
import TicketDetailComponent, { TicketDetailComponentType } from "./ticket-details-component.vue";
import { PropValidator } from "vue/types/options";
import { Ticket, EnumTicketStatus } from "easyclick-common/model/ticket-model";
import TicketService from "../services/ticket-service";
import { socketioTicketStart, socketioTicketStatus, socketioTicketStop, socketioTicketUpdate } from "easyclick-common/socketio";
import { EnumTrivalent } from "easyclick-common/model/enumeration";
import UserModule from "../store/modules/user-module";
import { UiLevelMatrix, UiTicketDetail } from "../model/ticketdetail";

type DataType = Record<string, never>;
type PropsType = { value: Ticket, isAdminMode: boolean, isActiveClient: boolean, isNewTicket: boolean, };
type ComputedType = {
    isActive: boolean,
    isActiveTicket: boolean,
    isAdminUser: boolean,
    isStopped: boolean,
    isCommentarActive: boolean,
    isTextRequired: EnumTrivalent[],
    commentRules2: string,
    commentRuleError: boolean,
    ticketDetailComp?: TicketDetailComponentType,
    commentarHint: string,
};
type MethodType = {
    close: () => void,
    save: () => void,
    sendUpdate: () => void,
    toggleStatus: () => void,
    toggleStatusTicket: (value: Ticket, isActive?: boolean) => void,
    setNewLevelMatrix: (lm: UiLevelMatrix) => void,
    convertTimeToReadable: (date: Date) => string,
};

export default Vue.extend<DataType, MethodType, ComputedType, PropsType>({
    name: "TicketEditComponent",
    components: {
        activeTimerComponent: TimerComponent,
        pauseTimerComponent: TimerComponent,
        ticketDetailComponent: TicketDetailComponent,
    },
    props: {
        value: { type: Object } as PropValidator<Ticket>,
        isAdminMode: { type: Boolean, default: false, required: false },
        isActiveClient: { type: Boolean, default: true, required: false },
        isNewTicket: { type: Boolean, default: false, required: true }
    },
    computed: {
        isActiveTicket() {
            return this.value?.status === EnumTicketStatus.Run;
        },
        isAdminUser() {
            return UserModule.isUserAdmin;
        },
        isActive() {
            return this.isAdminMode || (this.isActiveTicket && this.isActiveClient);
        },
        commentRules2() {
            return this.isTextRequired.find((x) => x === EnumTrivalent.Mandatory) ? "mdi-asterisk" : "";
        },
        commentRuleError() {
            return this.isTextRequired.find((x) => x === EnumTrivalent.Mandatory) ? true : false;
        },
        isTextRequired() {
            if (this.value?.ticketDetails) {
                return this.value.ticketDetails.map((x) => x.levelMatrix?.isTicketTextRequired ?? EnumTrivalent.Disabled);
            }
            return [EnumTrivalent.Disabled];
        },
        isCommentarActive() {
            return this.isActive && (Math.max(...this.isTextRequired) != EnumTrivalent.Disabled);
        },
        isStopped() {
            return this.value?.status === EnumTicketStatus.Stop;
        },
        ticketDetailComp() {
            return this.$refs.ticketDetail ? this.$refs.ticketDetail as unknown as TicketDetailComponentType : undefined;
        },
        commentarHint() {
            if (this.isCommentarActive)
                return "Kommentar benotigt";
            return "";
        }
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        value(newVal: Ticket, oldVal: Ticket) {
            if (oldVal !== newVal) {
                if (oldVal?.status === EnumTicketStatus.Run
                    && this.isActiveClient) {
                    this.toggleStatusTicket(oldVal);
                }
                if (newVal?.id) {
                    this.$socket.client.emit(socketioTicketStatus, newVal);
                }
            }
        }
    },
    methods: {
        sendUpdate() {
            this.$socket.client.emit(socketioTicketUpdate, this.value);
        },
        close() {
            this.$emit("close");
        },
        save() {
            if (!this.value) {
                return;
            }

            let promise: Promise<Ticket>;
            if ((this.value.id ?? -1) >= 0) {
                promise = TicketService.update(this.value);
            } else {
                promise = TicketService.add(this.value);
            }

            promise.then((res) => this.$emit("input", res)).finally(() => this.close()).catch((err) => err);
        },
        toggleStatus() {
            if (this.value) {
                this.toggleStatusTicket(this.value, this.isActive);
            }
        },
        toggleStatusTicket(value: Ticket, isActive = true) {
            if (value.status == EnumTicketStatus.Undefined) {
                value.start = new Date();
            }

            if (value.ticketDetails?.length === 1) {
                this.ticketDetailComp?.editItem(value.ticketDetails[0] as UiTicketDetail);
            }

            this.$socket.client.emit(isActive ? socketioTicketStop : socketioTicketStart, value);
        },
        setNewLevelMatrix(lm: UiLevelMatrix) {
            if (!this.disabled
                && this.value?.status == EnumTicketStatus.Run) {
                this.ticketDetailComp?.setNewLevelMatrix(lm);
            }
        },
        convertTimeToReadable(date: Date) {
            return moment(date).format("L LTS");
        }
    }
});
