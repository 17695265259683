
import moment from "moment";
import numeral from "numeral";
import Vue from "vue";
import { Column, ColumnList, Report, ReportList } from "easyclick-common/model/report-model";
import { ReportData, RowType } from "easyclick-common/model/reportdata-model";
import ReportService from "../services/report-service";
import TableComponent from "../components/base/table-component.vue";
import UserModule from "../store/modules/user-module";
import { catClient } from "../helper/logging";
import { EnumReportParam } from "easyclick-common/model/enumeration";
import { appName } from "easyclick-common/config";
import { getLocaleToUrlLocale } from "../plugins/i18n";
import { plainToInstance } from "class-transformer";

type ReportParameter = {
    type: string,
    name: string,
    value: unknown,
};

type ReportParameterList = ReportParameter[];

export default Vue.extend({
    name: "ReportingComponent",
    props: ["runFirstReport"],
    data: () => ({
        reports: [] as ReportList,
        report: new Report(),
        columns: [] as ColumnList,
        reportData: [] as ReportData,
        reportParameter: [] as ReportParameterList,
        loading: false,
        hasDataLoaded: false,
        appName: appName,
        footerProps: {
            ["items-per-page-options"]: [10, 50, 100, 200, 500, 1000, -1],
        },
    }),
    components: {
        tableComponent: TableComponent,
    },
    computed: {
        headers() {
            return this.columns.map((x) => calculateHeaderForTable(x, getLocaleToUrlLocale(this.$i18n.locale)));
        },
        locale() {
            return this.$i18n.locale;
        },
        localeShort() {
            return getLocaleToUrlLocale(this.$i18n.locale);
        },
        reportDataForTable() {
            return this.reportData.map((item) => {
                const newItem = {} as RowType;
                this.columns.forEach((x) => {

                    const keyName = Object.keys(item).find((y) => y.toUpperCase() == x.sourceColumn.toUpperCase());
                    if (keyName) {
                        newItem[x.getCaption(this.localeShort)] = item[keyName];
                    }
                });
                return newItem;
            });
        },
        isExportAllowed() {
            return this.hasDataLoaded && this.report.reportConfig.includes(EnumReportParam.ExportAllowed);
        }
    },
    methods: {
        executeReport() {
            this.loading = true;
            const reducedParams = this.reportParameter.reduce((a, v) => ({ ...a, [v.type]: v.value }), {});
            ReportService.getReportData(this.report, reducedParams)
                .then((reportData) => {
                    this.columns = this.report.columns;
                    if (reportData.length >= 1) {
                        const otherColumns = Object.keys(reportData[0])
                            .filter((x) => !this.columns.find((y) => y.sourceColumn.toUpperCase() == x.toUpperCase()))
                            .map((x) => (plainToInstance(Column, {
                                sourceColumn: x,
                            })));
                        this.columns.push(...otherColumns);
                    }
                    this.reportData.replace(reportData);
                    return true;
                })
                .catch((error) => {
                    this.resetReportData();
                    this.$toast.warning(this.$i18n.t("site.component.reporting.errorToastText"));
                    catClient.error("executeReport", error);
                })
                .finally(() => {
                    this.loading = false;
                    this.hasDataLoaded = true;
                });

        },
        ifTextField(name: string) {
            return name.toUpperCase().includes("STRING");
        },
        ifDateField(name: string) {
            return name.toUpperCase().includes("DATE");
        },
        toFormattedDate(value: string) {
            return moment(value).format("L");
        },
        resetReportData() {
            this.hasDataLoaded = false;
            this.columns.splice(0);
            this.reportData.splice(0);
        }
    },
    watch: {
        locale: {
            handler(newValue, oldValue) {
                if (newValue !== oldValue
                    && UserModule.isLoggedIn) {
                    ReportService.getReportList()
                        .then((reports) => {
                            this.reports = reports;
                            this.report = this.reports.find((x) => x.code === this.report.code) ?? this.reports[0];
                            this.resetReportData();
                            if (this.runFirstReport) {
                                this.executeReport();
                            }
                            return;
                        })
                        .catch((error) => catClient.error("localeHandler", error));
                }
            },
            immediate: true,
        },
        report: function (newValue: Report) {
            this.reportParameter = Object.entries(newValue?.parameters ?? []).map((pair) => ({
                type: pair[0],
                name: pair[1],
                value: this.ifDateField(pair[0]) ? moment().format("yyyy-MM-DD") : undefined as unknown,
            }));
            this.resetReportData();
        },
        runFirstReport: {
            handler(newValue) {
                if (newValue && this.reports.length > 0) {
                    if (this.report !== this.reports[0]) {
                        this.report = this.reports[0];
                    } else {
                        this.executeReport();
                    }
                }
            },
            immediate: true,
        },
    },
});

/**
 *
 * @param value
 * @param locale
 */
function calculateHeaderForTable(value: Column, locale: string): object {
    const obj = {
        text: value.getCaption(locale),
        value: value.getCaption(locale),
        sortable: value.sortable ?? true,
    };

    if (value.dateTimeFormat) {
        return {
            ...obj,
            formatter: (x: Date) => x ? moment(x).format(value.dateTimeFormat) : null,
        };
    } else if (value.numberFormat) {
        return {
            ...obj,
            formatter: (x: number) => x ? numeral(x).format(value.numberFormat) : null,
        };
    }

    return obj;
}
