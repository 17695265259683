import { Ticket, TicketList } from "easyclick-common/model/ticket-model";
import axios, { ComposedError } from "../helper/axios";
import { TICKET_ROUTE } from "easyclick-common/routes";
import moment from "moment";
import IdentificationService from "./identification-service";


/**
 *
 */
class TicketService extends IdentificationService<Ticket> {

    constructor() {
        super(TICKET_ROUTE);
    }

    /**** Get All Ticket Lists ****/
    getTicketList(startTime: Date, endTime: Date, isAdmin: boolean): Promise<TicketList> {
        return axios.get(TICKET_ROUTE, {
            params: {
                start: moment(startTime).format("yyyy-MM-DD"),
                end: moment(endTime).format("yyyy-MM-DD"),
                isAdmin
            }
        })
            .then((res) => res.data as TicketList)
            .catch((error: ComposedError) => {
                throw error.handleGlobally("getTicketLists");
            });
    }
}

export default new TicketService();
