import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";
import moment from "moment";
import vuetify from "./vuetify";
import { headerAcceptLanguage } from "easyclick-common/config";
import { EnumLanguage } from "easyclick-common/model/enumeration";
import { LANG_ROUTE } from "easyclick-common/routes";

Vue.use(VueI18n);

export const defaultLanguage = EnumLanguage.English;

export const i18n = new VueI18n({
    locale: "_", // empty string --> "en-US"
    fallbackLocale: defaultLanguage,
    silentFallbackWarn: !Vue.config.devtools,
});

/**
 *
 */
export function getLoadedLanguages(): string[] {
    return Object.keys(i18n.messages);
}

/**
 * @param lang
 */
function setI18nLanguage(lang: EnumLanguage): string {
    i18n.locale = lang;
    axios.defaults.headers.common[headerAcceptLanguage] = getLocaleToUrlLocale(lang);

    const locale = getBrowserMainLanguage(); //"en";
    moment.locale(locale);

    vuetify.framework.lang.current = getLocaleToUrlLocale(lang);
    return lang;
}

/**
 * @param locale
 */
export function getLocaleToUrlLocale(locale: EnumLanguage | string): string {
    return locale.substring(0, 2);
}

/**
 * @param locale
 */
export function getUrlLocaleToLocale(locale: string): EnumLanguage | undefined {
    return Object.values(EnumLanguage).find((x) => x.startsWith(locale));
}

/**
 *
 */
export function toggleBetweenLanguages(): EnumLanguage {
    return i18n.locale === EnumLanguage.German ? EnumLanguage.English : EnumLanguage.German;
}

/**
 *
 */
export function getBrowserMainLanguage(): EnumLanguage {
    return window.navigator.language as EnumLanguage;
}

/**
 * @param lang
 */
export function loadLanguageAsync(lang: EnumLanguage): Promise<string> {
    if (lang === undefined) {
        return Promise.resolve("No language defined to be set!");
    }

    // If the language was already loaded:
    if (getLoadedLanguages().includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    // If the language hasn't been loaded yet
    return axios.get(`${LANG_ROUTE}/${lang}`)
        .then((res) => {
            i18n.setLocaleMessage(lang, res.data);
            return setI18nLanguage(lang);
        });
}
