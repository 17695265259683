
import Vue from "vue";
import { appName } from "easyclick-common/config";

export default Vue.extend({
    name: "AboutComponent",
    props: {
        msg: String,
    },
    data: () => ({
        appName: appName,
    }),
});
