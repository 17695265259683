import store from "../index";
import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import LoginService from "../../services/login-service";
import { ComposedError } from "../../helper/axios";
import { catStore } from "../../helper/logging";
import { TokenResponse, LoginUser, User, UserList, UserSettings } from "easyclick-common/model/user-model";
import { i18n } from "../../plugins/i18n";
import UserService from "../../services/user-service";
import { localTokenName } from "../../helper/local-storage";
import { connectSocketIo, disconnectSocketIo } from "../../plugins/socketio";
import { logout } from "../../helper/logout";
import Vue from "vue";

/**
 *
 */
@Module({
    name: "user",
    store: store,
    namespaced: true,
    dynamic: true,
})
class UserModule extends VuexModule {

    token = localStorage.getItem(localTokenName) || null;

    @Action({ commit: "setUser" })
    private _loggedOffUser(): User {
        return Object.assign(new LoginUser, {
            firstName: "Nicht angemeldet",
            middleName: "",
            lastName: "",
            permissions: [],
            settings: new UserSettings(),
        });
    }

    get userDisplayName(): string {
        if (this.isLoggedIn) {
            return this.user.firstName + " " + this.user.lastName;
        } else {
            return i18n.t("object.user.loggedOff.name").toString();
        }
    }

    user = this._loggedOffUser();
    users: UserList = [];

    get isLoggedIn(): boolean {
        return this.token !== null;
    }

    get isUserAdmin(): boolean {
        return this.user.permissions.some((x) => x.key === "ADMIN");
    }

    get jwtToken(): string {
        if (this.token)
            return this.token;
        else
            return "";
    }

    get ownUser(): User {
        return this.user;
    }

    get usersAll(): UserList {
        return this.users;
    }

    @Action
    loginUser(user: LoginUser): Promise<TokenResponse> {
        return LoginService.login(user)
            .then((response) => {

                // save token
                catStore.info("Received token " + response.token);
                this.context.commit("saveToken", response.token); //in vuex store

                //go back to login component
                return response;
            })
            .catch((error: ComposedError) => {
                // We want to handle globally
                //error.handleGlobally && error.handleGlobally();
                throw error.handleGlobally("loginUser");
            });
    }

    @Action
    updateUser(): Promise<User> {
        if (this.isLoggedIn) {
            return this.retrieveUser();
        } else {
            return Promise.resolve(this._loggedOffUser());
        }
    }

    @Action({ commit: "updateSettings" })
    updateUserSettings(settings?: UserSettings) {
        return UserService.updateUserSettings(settings ?? this.user.settings);
    }

    @Action
    storeSelectedProject(projectId: string) {
        this.context.commit("STORE_SELECTED_PROJECT", projectId); //in vuex store
    }

    @Action //({ commit: "setUser" })
    retrieveUser(): Promise<User> {
        return UserService.getOwnUser().then((user) => {
            this.context.commit("setUser", user);
            connectSocketIo();
            return user;
        });
    }

    @Action({ commit: "saveToken" })
    socketusertoken(token: string) {
        if (!token) {
            Vue.$toast.error(i18n.t("site.axios.errorUnauthorized"));
            logout();
        }
        return token;
    }

    @Action
    logoutUser(): Promise<boolean> {
        try {
            this.saveToken();
            disconnectSocketIo();
            return Promise.resolve(true);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    @Mutation
    saveToken(token?: string) {
        if (token) {
            localStorage.setItem(localTokenName, token); // in localstorage
            this.token = token;
        } else {
            localStorage.removeItem(localTokenName);
            this.token = null;
        }
    }

    @Mutation
    setUser(user: User) {
        this.user = user;
    }

    @Mutation
    updateSettings(settings: UserSettings) {
        this.user.settings = settings;
    }
}

export default getModule(UserModule);
