import { plainToInstance } from "class-transformer";
//import { StateTime } from "easyclick-common/model/statetime-model";
import { NewPassUser, User, UserSettings } from "easyclick-common/model/user-model";
import { /*USER_PASSWORD_ROUTE,*/ USER_OWN_ROUTE, USER_PASSWORD_RESET_ROUTE, USER_ROUTE, USER_SETTINGS_ROUTE } from "easyclick-common/routes";
import axios, { ComposedError } from "../helper/axios";

/**
 *
 */
export default class UserService {
    /*
static changePassword(changePassword: ChangePassword): Promise < DefaultResult > {
return axios.post(USER_PASSWORD_ROUTE, changePassword)

    .catch((error) => {
        // do not handle it globally because it will throw
        // an error toast on failed login
        throw error.handleGlobally("changePassword");
    })
    .then((res) => {
        if (res.status === 201) { // Password change successful
            return res.data;
        } else {
            const resError = res.data as unknown as ErrorResponseType;
            if (resError.errorCode) {
                throw EnumErrorCode[resError.errorCode];
            }

            throw false;
        }
    });
}
*/

    static updateUserSettings(settings: UserSettings): Promise<UserSettings> {
        return axios.post(USER_SETTINGS_ROUTE, settings)
            .catch((error: ComposedError) => {
                // do not handle it globally because it will throw
                // an error toast on failed login
                throw error.handleGlobally("updateUserSettings");
            })
            .then((res) => plainToInstance(User, res.data).settings);
    }

    static resetUserPassword(user: User, newPass?: string, oldPass?: string): Promise<User | undefined> {
        const userToSend = {
            ...user,
            newPass,
            oldPass,
        } as NewPassUser;
        return axios.post(USER_PASSWORD_RESET_ROUTE, userToSend)
            .then((res) => res.data)
            .catch((error: ComposedError) => {
                throw error.handleGlobally("resetUserPassword");
            });
    }

    static retrieveUserSettings(): Promise<UserSettings> {
        return axios.get(USER_SETTINGS_ROUTE)
            .then((res) => plainToInstance(UserSettings, res.data))
            .catch((error: ComposedError) => {
                // do not handle it globally because it will throw
                // an error toast on failed login
                throw error.handleGlobally("retrieveUserSettings");
            });
    }

    static getOwnUser(): Promise<User> {
        return axios.get(USER_OWN_ROUTE)
            .then((res) =>
                // handle success
                res.data
            )
            .catch((error: ComposedError) => {
                // We want to handle globally
                throw error.handleGlobally();
            });
    }

    static getUserList(): Promise<User[]> {
        return axios.get(USER_ROUTE)
            .then((res) => res.data)
            .catch((error: ComposedError) => {
                throw error.handleGlobally();
            });
    }
}
