
import Vue from "vue";
import { appName } from "easyclick-common/config";
import { logout } from "../helper/logout";

export default Vue.extend({
    name: "LogoutDialogView",
    data: () => ({
        appName: appName,
    }),
    methods: {
        execute() {
            logout();
        },
        cancel() {
            this.$router.go(-1);
        },
    },
});
