import { EnumBoolean } from "easyclick-common/model/enumeration";
import { UserLevelMatrix } from "easyclick-common/model/levelmatrix-model";
import { UserLevelVal } from "easyclick-common/model/levelval-model";

/**
 *
 */
export interface TreeNode<T extends TreeNode<T>> {
    /**
     *
     */
    get id(): number;
    /**
     *
     */
    get name(): string;
    disabled: boolean;
    children?: TreeNodeList<T>;
    parentNode?: TreeNode<T>;
    /**
     *
     */
    get isEndPoint(): boolean;
}

export type TreeNodeList<T extends TreeNode<T>> = T[];

export type ArrayToTreeRes<T extends TreeNode<T>> = {
    treeNodes: TreeNodeList<T>,
    preOpenedDefaultNodes: TreeNodeList<T>,
    preOpenedUserNodes: TreeNodeList<T>,
};

export type TreeNodePath = string;
export type TreeNodePathList = TreeNodePath[];

export type TreeNodeEasyClickList<T extends UserLevelVal, U extends UserLevelMatrix> = TreeNodeEasyClick<T, U>[];

/**
 *
 */
export class TreeNodeEasyClick<T extends UserLevelVal, U extends UserLevelMatrix> implements TreeNode<TreeNodeEasyClick<T, U>> {
    id: number; // refers to generatedId.
    get name(): string {
        return this.levelVal.name;
    } // Needed for name display.
    children?: TreeNodeEasyClickList<T, U>;
    parentNode?: TreeNodeEasyClick<T, U>;
    disabled = false;
    levelVal: T; // refers to levelVal LevelInformation entry.
    levelMatrix?: U;
    get isEndPoint() {
        return !!this.levelMatrix;
    }

    get hasChildren() {
        return this.children ? this.children?.length > 0 : false;
    }

    constructor(id: number, levelVal: T) {
        this.id = id;
        this.levelVal = levelVal;
    }
}

/**
 *
 * @param matrixList
 * @param levelValList
 * @param userOpenedList
 */
export function arrayToTree<T extends UserLevelVal, U extends UserLevelMatrix>(matrixList: U[], levelValList: T[], userOpenedList: TreeNodePathList = []):
    ArrayToTreeRes<TreeNodeEasyClick<T, U>> {
    // Create map of lookup list from levelval.
    const mapLevelVals = [] as T[];
    for (const levelValEntry of levelValList) {
        mapLevelVals[levelValEntry.id] = levelValEntry;
    }

    // Needs to be consistent over addChild, so these vars are declared here.
    let generatedId = -1;

    const result: ArrayToTreeRes<TreeNodeEasyClick<T, U>> = {
        treeNodes: [],
        preOpenedDefaultNodes: [],
        preOpenedUserNodes: [],
    };

    // Helper function for adding child nodes if they do not exists
    /**
     *
     * @param item
     * @param levelValId
     */
    function addChild(levelValId: number, item?: TreeNodeEasyClick<T, U>): TreeNodeEasyClick<T, U> {
        if (item
            && !item.children) {
            item.children = [];
        }

        const list = item?.children ?? result.treeNodes;

        const childItem = list.find((x) => x.levelVal.id === levelValId);

        if (childItem != undefined)
            return childItem;

        const levelVal = mapLevelVals[levelValId];

        if (levelVal === undefined) {
            console.log("could not find " + levelValId);
            throw ("could not find " + levelValId);
        }

        const newChild = Object.assign(new TreeNodeEasyClick<T, U>(--generatedId, levelVal), {
            parentNode: item,
        });

        // Save all preopened entries!
        if (levelVal.isPreOpened === EnumBoolean.Enabled) {
            result.preOpenedDefaultNodes.push(newChild);
        }

        const currPath = nodeToPathString(newChild);

        // Save all previously user-opened entries!
        const resfind = userOpenedList.find((x) => x === currPath);
        if (resfind !== undefined) {
            result.preOpenedUserNodes.push(newChild);
        }

        list.push(newChild);

        return newChild;
    }
    // Iterate over matrix
    for (const matrixEntry of matrixList) {
        let currNode = undefined;

        if (!matrixEntry.levelValList)
            break;

        // Iterate through levelValList of matrixEntry and add children one by one.
        for (const levelValId of matrixEntry.levelValList) {
            // Is currNode levelVal not active? Then stop adding sub entries
            currNode = addChild(levelValId, currNode);
        }

        if (currNode) {
            currNode.levelMatrix = matrixEntry;
        }
    }

    sortSubNodes(result.treeNodes);

    return result;
}

/**
 *
 * @param item
 * @param items
 */
function sortSubNodes<T extends UserLevelVal, U extends UserLevelMatrix>(items?: TreeNodeEasyClick<T, U>[]) {
    if (items && items.length > 0) {
        items.sort((a, b) => {
            if (a.levelVal.order === b.levelVal.order) {
                return a.levelVal.name.localeCompare(b.levelVal.name);
            }
            return a.levelVal.order - b.levelVal.order;
        });
        items.forEach((x) => sortSubNodes(x.children));
    }
}

/**
 *
 * @param node - Open all sub entries for node.
 */
export function getAllSubChildren<T extends TreeNode<T>>(node: T): TreeNodeList<T> {
    const res = [node];
    if (node.children) {
        node.children.forEach((child) => {
            if (child.children) {
                res.push(...getAllSubChildren(child));
            }
        });
    }

    return res;
}


/**
 *
 * @param node
 */
export function nodeToPathString<T extends UserLevelVal, U extends UserLevelMatrix>(node: TreeNodeEasyClick<T, U>): TreeNodePath {
    if (node.parentNode) {
        return nodeToPathString(node.parentNode) + "," + node.levelVal.id; // Iterate until not parentNode is there eg. root node is reached.
    }

    return node.levelVal.id.toString();
}
