import { plainToInstance } from "class-transformer";
import { ServerInfo } from "easyclick-common/model/serverinfo-model";
import { SERVERINFO_ROUTE } from "easyclick-common/routes";
import axios from "../helper/axios";

/**
 *
 */
export default class ServerInfoService {
  static getServerInfo(): Promise<ServerInfo> {
    return axios.post(SERVERINFO_ROUTE)
      .then((res) => plainToInstance(ServerInfo, res.data));
  }
}
