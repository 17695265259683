
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { logout } from "../helper/logout";
import UserService from "../services/user-service";
import UserModule from "../store/modules/user-module";

/**
 *
 */
interface PasswordChangeData {
    isOldPwdVisible: boolean;
    isNewPwdVisible: boolean;
    isConfirmNewPwdVisible: boolean;
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    passwordNotLongEnough: Array<(v: string) => boolean | string | TranslateResult>;
    passwordNotComplexEnoughField: Array<(v: string) => boolean | string | TranslateResult>;
}

export default Vue.extend({
    name: "PasswordChangeComponent",
    data(): PasswordChangeData {
        return {
            isOldPwdVisible: false,
            isNewPwdVisible: false,
            isConfirmNewPwdVisible: false,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            passwordNotLongEnough: [
                (v: string) =>
                    v.length >= 11 ||
                    this.$i18n.t("site.component.password.passwordNotLongEnough"),
            ],
            passwordNotComplexEnoughField: [
                (v: string) =>
                    /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-zäüö])(?=.*[A-ZÄÜÖ]).*/.test(v) ||
                    this.$i18n.t("site.component.password.passwordNotComplexEnoughField"),
            ],
        };
    },
    computed: {
        isFormValid(): boolean {
            return (
                this.oldPassword !== "" &&
                this.newPassword !== "" &&
                this.confirmPassword !== "" &&
                this.passwordNotLongEnough.every((rule) => rule(this.newPassword) === true) &&
                this.passwordNotComplexEnoughField.every((rule) => rule(this.newPassword) === true) &&
                this.passwordDoNotMatch(this.confirmPassword) === true &&
                this.passwortNotDifferent(this.newPassword) === true
            );
        },
    },
    methods: {
        passwordDoNotMatch(v: string) {
            return v === this.newPassword || this.$i18n.t("site.component.password.passwordDoNotMatch");
        },
        passwortNotDifferent(v: string) {
            return v !== this.oldPassword || this.$i18n.t("site.component.password.passwortNotDifferent");
        },
        submit() {
            UserService.resetUserPassword(UserModule.user, this.newPassword, this.oldPassword)
                .then((data) => {
                    if (typeof data === "string" && data === "OPW") {
                        Vue.$toast.error(
                            this.$i18n.t("site.component.password.passwordChangeFailedWrongOld").toString()
                        );
                        return;
                    }
                    Vue.$toast.success(
                        this.$i18n.t("site.component.password.passwordChangedSuccessfully").toString()
                    );
                    logout();
                    return;
                })
                .catch(() => {
                    Vue.$toast.error(
                        this.$i18n.t("site.component.password.passwordChangeFailed").toString()
                    );
                });
        },
    },
});
