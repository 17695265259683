var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-navigation-drawer',{ref:"navDrawer",attrs:{"app":"","width":"300","disable-resize-watcher":"","permanent":"","expand-on-hover":_vm.expandOnHover,"mini-variant":_vm.isNavigationMini,"transition-duration":"0s"},on:{"update:miniVariant":function($event){_vm.isNavigationMini=$event},"update:mini-variant":function($event){_vm.isNavigationMini=$event},"transitionend":_vm.navDrawerTransitionEnd}},[_c('v-card',{staticClass:"d-flex fill-height flex-column"},[_c('v-card',{staticClass:"fill-height",attrs:{"flat":"","tile":""}},[_c('v-card',{attrs:{"flat":"","tile":"","color":_vm.backgroundColor}},[_c('v-img',{staticClass:"pa-1",attrs:{"height":"65","src":'/images/' + _vm.logoImage}})],1),_c('v-card',{attrs:{"flat":"","tile":"","color":_vm.backgroundColor}},[_c('v-img',{staticClass:"pa-1",attrs:{"height":"230","src":'/images/' + _vm.backgroundImage}},[_c('v-list',[_c('v-list-item',{class:_vm.isNavigationMini ? 'pa-0' : 'pa-4'},[_c('v-sheet',{staticClass:"pa-1",attrs:{"elevation":"10","rounded":"lg","height":_vm.isNavigationMini ? 48 : 64,"width":_vm.isNavigationMini ? 48 : 64}},[_c('v-icon',{attrs:{"alt":"easyClick Icon","color":"secondary","size":_vm.isNavigationMini ? 40 : 56}},[_vm._v(" mdi-cursor-default-click-outline ")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","small":"","color":"secondary","title":_vm.$t('site.general.languageChange')},on:{"click":_vm.toggleLanguage}},[_c('v-icon',[_vm._v("mdi-translate")])],1),_c('v-menu',{attrs:{"offset-y":"","nudge-left":"140","nudge-bottom":"10","rounded":"xl"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-3",attrs:{"fab":"","small":"","color":"secondary","title":_vm.$t(
                                                'site.general.changeMainColor'
                                            )}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-palette")])],1)]}}]),model:{value:(_vm.colorMenuOpen),callback:function ($$v) {_vm.colorMenuOpen=$$v},expression:"colorMenuOpen"}},[_c('v-card',[_c('v-container',_vm._l((_vm.colorGrid),function(colorRow,rowIndex){return _c('v-row',{key:rowIndex},_vm._l((colorRow),function(colorEntry,colIndex){return _c('v-col',{key:colIndex,attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"fab":"","color":colorEntry.base},on:{"click":function($event){_vm.selectedThemeColor =
                                                            colorEntry}}},[(
                                                            _vm.selectedThemeColor ==
                                                            colorEntry
                                                        )?_c('v-icon',{attrs:{"color":"white","large":""}},[_vm._v("mdi-check")]):_vm._e()],1)],1)}),1)}),1)],1)],1),_c('v-btn',{attrs:{"fab":"","small":"","dark":!_vm.darkModeActive,"light":_vm.darkModeActive,"title":_vm.$t('site.general.changeTheme')},on:{"click":_vm.toggleDarkmode}},[_c('v-icon',[_vm._v("mdi-theme-light-dark")])],1)],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.showUserInfo),expression:"showUserInfo"}],attrs:{"role":"complementary","aria-label":_vm.$t('site.component.navigation.userInfo')}},[_c('userInfoComponent')],1)],1)],1)],1),_c('v-divider'),_c('v-list',{attrs:{"nav":"","dense":""}},[_vm._l((_vm.routes),function(route){return [(!route.children)?_c('v-list-item',{key:route.path,attrs:{"to":{
                            name: route.name,
                            params: { locale: _vm.locateUrl },
                        },"color":"primary"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(route.meta?.nav?.icon))])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(route.meta?.nav?.title())+" ")])],1):_c('v-list-group',{key:route.path,attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(route.meta?.nav?.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(route.meta?.nav?.title())+" ")])],1)]},proxy:true}],null,true)},_vm._l(((
                                route.children ?? []
                            ).filter((x) => x.meta?.nav !== undefined)),function(subRoute){return _c('v-list-item',{key:subRoute.path,attrs:{"to":{
                                name: subRoute.name,
                                params: { locale: _vm.locateUrl },
                            }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" "+_vm._s(subRoute.meta?.nav?.icon)+" ")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(subRoute.meta?.nav?.title())+" ")])],1)}),1)]})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }