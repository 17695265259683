import VueSocketIO from "vue-socket.io-extended";
import Vue from "vue";
import io from "socket.io-client";
import UserModule from "../store/modules/user-module";
import store from "../store";

export const socket = io({
    autoConnect: false,
    // eslint-disable-next-line @typescript-eslint/ban-types
    auth: (cb: Function) => {
        cb({
            token: UserModule.jwtToken,
        });
    }
});

Vue.use(VueSocketIO, socket, {
    store,
    actionPrefix: "socket", // (1) keep prefix in uppercase
    eventToActionTransformer: (actionName: string) => actionName, // (2) cancel camelcasing
});


/**
 *
 */
export function connectSocketIo() {
    if (!socket.connected) {
        socket.connect();
    }
}
/**
 *
 */
export function disconnectSocketIo() {
    if (socket.connected) {
        socket.disconnect();
    }
}
