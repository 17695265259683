
import Vue from "vue";
import { colorMainBackground } from "../plugins/vuetify";
import UserModule from "../store/modules/user-module";

export default Vue.extend({
    name: "MainComponent",
    computed: {
        backgroundColor() {
            return colorMainBackground;
        },
        loggedIn() {
            return UserModule.isLoggedIn;
        }
    }
});

