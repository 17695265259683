import { catVue } from "./logging";
import UserModule from "../store/modules/user-module";
import { router, ROUTE_LOGIN } from "../plugins/router";
/**
 *
 */
export function logout() {
    UserModule.logoutUser()
        .then(() => {
            catVue.debug("push user to /login");
            router.push({ name: ROUTE_LOGIN }); //move user to login
            return;
        })
        .catch((error) => {
            //not really expected but who knows
            catVue.error(error, null);
        });
}
