
import Vue from "vue";
import { formatDuration } from "../../helper/helper";
const timerCount = 1000;

export default Vue.extend({
    name: "TimerComponent",
    props: {
        running: {
            type: Boolean,
        },
        value: {
            type: Number,
            default: 0,
        },
    },
    data: () => ({
        timer: -1
    }),
    watch: {
        running(newVal) {
            if (newVal) {
                this.startT();
            }
            else {
                this.stopT();
            }
        },
    },
    mounted() {
        if (this.running) {
            this.startT();
        }
    },
    methods: {
        stopT() {
            clearInterval(this.timer);
        },
        startT() {
            this.timer = window.setInterval(() => {
                this.$emit("input", this.value + timerCount);
            }, timerCount);
        },
    },
    filters: {
        timeHumanReadable(value: number) {
            return formatDuration(value, "milliseconds", "h:m:ss", { stopTrim: "m", trim: "largest" });
        },
    },
});
