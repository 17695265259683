

import { EnumTicketStatus, Ticket, TicketList } from "easyclick-common/model/ticket-model";
import TicketEditComponent from "../components/ticket-edit-component.vue";
import Vue from "vue";
import { LevelMatrix } from "easyclick-common/model/levelmatrix-model";
import { socketioTicketAdd, socketioTicketStatus, socketioTicketStop, socketioTicketClientActive, socketioTicketRemove } from "easyclick-common/socketio";
import { socket } from "../plugins/socketio";
import LevelmatrixModule from "../store/modules/levelmatrix-module";

export default Vue.extend({
    name: "TicketTabComponent",
    components: {
        ticketEditComponent: TicketEditComponent,
    },
    data: () => ({
        tickets: [] as TicketList,
        tab: 0,
        activeClientTicket: "",
        myClientId: socket.id,
    }),
    watch: {
        tab(newVal) {
            // tab logic set tab to 0 if "add"-tab is active, change it to the new ticket.
            if (newVal == 0) {
                this.tab = this.tickets.length;
            }
        },
        levelMatrixLoaded(newVal) {
            if (newVal === true) {
                this.$socket.client.emit(socketioTicketStatus);
            }
        }
    },
    computed: {
        loading() {
            return this.tickets.length == 0;
        },
        levelMatrixLoaded() {
            return LevelmatrixModule.levelMatrixList.length > 0;
        }
    },
    created() {
        this.$socket.client.on(socketioTicketClientActive, (clientId: string) => {
            this.activeClientTicket = clientId;
        });
        this.$socket.client.on("connect", () => {
            if (this.myClientId === this.activeClientTicket
                && this.myClientId !== this.$socket.client.id) {
                this.$socket.client.emit(socketioTicketClientActive, this.myClientId);
            }
            this.$socket.client.emit(socketioTicketStatus);
            this.myClientId = this.$socket.client.id;
        });
        this.$socket.client.on(socketioTicketStop, (id: Ticket["id"]) => {
            this.tickets.filter((x) => id !== x.id && this.isTicketRun(x)).forEach((x) => x.status = EnumTicketStatus.Stop);
        });
        this.$socket.client.on(socketioTicketRemove, (id: Ticket["id"]) => {
            const index = this.tickets.findIndex((x) => x.id == id);
            if (index >= 0) {
                this.tickets.splice(index, 1);
            }
            if (this.tickets.length == 0
                && this.myClientId == this.activeClientTicket) {
                this.addNewTicket();
            }
        });
        this.$socket.client.on(socketioTicketAdd, (ticket: Ticket) => {
            if (!ticket.ticketDetails) {
                ticket.ticketDetails = [];
            }
            this.tickets.push(ticket);
            this.tab = this.tickets.length;
        });
        this.$socket.client.on(socketioTicketStatus, (tickets: TicketList | Ticket) => {
            const func = (fncticket: Ticket) => {
                if (fncticket.status !== EnumTicketStatus.Finish) {
                    fncticket.ticketDetails?.forEach((x) => x.levelMatrix = LevelmatrixModule.levelMatrixList.find((y) => y.id === x.levelMatrix?.id));
                    const intTicket = this.tickets.find((x) => x.id === fncticket.id);
                    if (intTicket) {
                        Object.assign(intTicket, fncticket);
                    } else {
                        this.tickets.push(fncticket);
                    }
                }
            };
            if (Array.isArray(tickets)) {
                for (const ticket of tickets) {
                    func(ticket);
                }
            } else {
                func(tickets);
            }
            if (this.tab == 0) {
                const index = this.tickets.findIndex((x) => x.status == EnumTicketStatus.Run);
                this.tab = index >= 0 ? index + 1 : 1;
            }
        });
    },
    methods: {
        isTicketRun(ticket: Ticket): boolean {
            return ticket.status === EnumTicketStatus.Run;
        },
        addNewTicket() {
            this.$socket.client.emit(socketioTicketAdd);
        },
        setNewLevelMatrix(lm: LevelMatrix) {
            if (this.$refs.ticketDialog) {
                (this.$refs.ticketDialog as unknown as { setNewLevelMatrix: (lm: LevelMatrix) => void, }).setNewLevelMatrix(lm);
            }
        },
        cancelTicket() {
            const ticket = this.tickets[this.tab - 1];
            this.$socket.client.emit(socketioTicketRemove, ticket.id);
        }
    }
});
