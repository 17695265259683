
import Vue from "vue";
import { LoginMethodType } from "easyclick-common/model/serverinfo-model";
import LoginFormComponent from "../components/base/loginform-component.vue";
import { i18n } from "../plugins/i18n";
import { ROUTE_DEFAULT } from "../plugins/router";
import UserModule from "../store/modules/user-module";
import ServerInfoModule from "../store/modules/serverinfo-module";

export default Vue.extend({
    name: "LoginView",
    components: { loginFormComponent: LoginFormComponent },
    data: () => ({
        maxWidth: 500,
        loading: false,
        error: false,
    }),
    computed: {
        logins() {
            return ServerInfoModule.serverInfo.loginMethods.filter((x) => x.type !== LoginMethodType.Intern && x.url !== undefined)
                .map((x) => {
                    switch (x.type) {
                        case LoginMethodType.SamlAdfs: {
                            return {
                                type: LoginMethodType.SamlAdfs,
                                textTitle: () => i18n.t("site.views.login.buttonTitleAdfs"),
                                textAdditionalInfo: () => i18n.t("site.views.login.buttonAdditionalInfoAdfs"),
                                click: () => location.replace(x.url ?? ""),
                                icon: "mdi-cloud-lock-outline"
                            };
                        }
                    }
                });
        },
        isLoggedIn() {
            return UserModule.isLoggedIn;
        },
        internalLoginAvailable() {
            return !this.loading && ServerInfoModule.serverInfo.loginMethods.find((x) => x.type == LoginMethodType.Intern);
        }
    },
    methods: {
        submitSuccess() {
            this.$router.push({
                name: ROUTE_DEFAULT,
            },
            );
        },
    },
});
