export { };
Array.prototype.replace = function (o: []) {
    if (o) {
        this.splice(0); // Needed for Vue(X)! Otherwise, values in components won't receive update!
        o.forEach((x) => this.push(x));
    } else {
        console.warn("Array.prototype.replace: o is not set!");
    }
};

declare global {
    /**
     *
     */
    interface Array<T> {
        /**
         *
         */
        replace(o: Array<T>): void;
    }
}
