
import Vue from "vue";
import TicketTableComponent from "../components/ticket-table-component.vue";

export default Vue.extend({
    name: "TicketOverviewView",
    components: {
        ticketTableComponent: TicketTableComponent,
    }
});

