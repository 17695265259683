

import Vue from "vue";
import MatrixTreeComponent from "../components/matrix-tree-component.vue";
import TicketTabComponent from "../components/ticket-tab-component.vue";
import { LevelMatrix } from "easyclick-common/model/levelmatrix-model";

export default Vue.extend({
    name: "TicketView",
    components: {
        matrixTreeComponent: MatrixTreeComponent,
        ticketTabComponent: TicketTabComponent,
    },
    methods: {
        setNewLevelMatrix(lm: LevelMatrix) {
            if (this.$refs.ticketTab) {
                (this.$refs.ticketTab as unknown as { setNewLevelMatrix: (lm: LevelMatrix) => void, }).setNewLevelMatrix(lm);
            }
        }
    }
});
