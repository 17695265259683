import Vue from "vue";
import axios, { AxiosError, AxiosHeaders } from "axios";
import { catHelper, catService } from "./logging";
import { headerJwtRequest, headerJwtResponse, headerVersionRequest } from "easyclick-common/config";
import UserModule from "../store/modules/user-module";
import { i18n } from "../plugins/i18n";
import { JwtHelper } from "./helper";
import store from "../store";
import { logout } from "./logout";
import { EnumErrorCode, ErrorResponseType } from "easyclick-common/error";

/**
 *
 */
export interface ComposedError {
    readonly message: string | undefined;
    readonly error: AxiosError;
    /**
     *
     */
    handleGlobally(consoleMessage?: string): ComposedError;
    /**
     *
     */
    getError(): AxiosError;
    /**
     *
     */
    errorCode(): EnumErrorCode | undefined;
}

/**
 *
 */
class ComposedAjaxError implements ComposedError {
    name = "ComposedAjaxError";
    public readonly message: string | undefined;
    public readonly error: AxiosError<ErrorResponseType>;

    private _globallyHandled = false;

    constructor(error: AxiosError<ErrorResponseType>) {
        catHelper.debug(JSON.stringify(error));

        this.error = error;

        this.message = this._calculateMessage();
    }

    private _calculateMessage(): string | undefined {
        switch (this.statusCode()) {
            case 401:
                return i18n.t("site.axios.errorUnauthorized").toString();
            case 404:
                return i18n.t("site.axios.errorNotFound").toString();
            case 503:
                return i18n.t("site.axios.errorServiceUnavailable").toString();
            default: {
                const errorMsg = this.error.response ? this.error.response?.data.message : this.error.message; // error.message contains sth like "Network Error" when client is not running.
                return errorMsg && typeof errorMsg === "string" ? "Error: " + errorMsg : undefined;
            }
        }
    }

    errorCode(): EnumErrorCode | undefined {
        const test = this.error.response?.data as ErrorResponseType;
        if (test.errorCode
            && Object.keys(EnumErrorCode).includes(test.errorCode)) {
            return EnumErrorCode[test.errorCode];
        }

        return undefined;
    }

    statusCode(): number | null {
        return this.error.response ? this.error.response.status : null;
    }

    public getError(): AxiosError {
        return this.error;
    }

    public handleGlobally(consoleMessage?: string): ComposedError {
        if (this._globallyHandled) return this;
        this._globallyHandled = true;

        // custom code addtional to messages that should only get executed when
        // the global error handler is executed
        switch (this.statusCode()) {
            case 401: {
                const errorCode = this.errorCode();
                if (errorCode == EnumErrorCode.DB_ERROR) {
                    //localStorage.setItem(localStorageVersionUpdate, store.state.appVersion);
                    window.location.reload();
                } else {
                    logout();
                }
                break;
            }
            default:
                if (consoleMessage) {
                    catService.error(consoleMessage, null);
                }
        }

        if (this.message) {
            Vue.$toast.warning(this.message);
        }

        return this;
    }
}

// Add a request interceptor
// this sets the JWT Header for API requests if apiToken is set in localStorage
axios.interceptors.request.use(function (config) {
    if (config.headers
        && config.headers instanceof AxiosHeaders) {
        const apiToken = UserModule.token;

        if (apiToken) {
            //catHelper.debug(`Send with request ${config.url}:  ${apiToken}`);
            config.headers.set(headerJwtRequest, apiToken, true);
        }

        config.headers.set(headerVersionRequest, store.state.appVersion, true);
    }

    return config;
}, function (error) {
    // Do something with request error
    catHelper.error("axios.interceptors.request.use error", null);
    return Promise.reject(error);
});


//all axios responses will go through this interceptor
axios.interceptors.response.use((response) => {
    // check if the API sent a renewed jwt token
    const token = response.headers[headerJwtResponse];
    if (token) {

        catHelper.debug(`response got a renewed token: Response ${response.config.url} \nToken: ${JSON.stringify(JwtHelper.decodeToken(token))}`);

        // save token
        UserModule.saveToken(token);
    }

    return response;
}, (error) => {
    // errorComposer will compose a handleGlobally function
    // see https://medium.com/@ejjay/a-short-ajax-story-on-error-handlers-8baeeccbc062
    // for further explanatio
    catHelper.error("Error in axios.interceptors.response", error);
    throw new ComposedAjaxError(error);
});

export default axios;
