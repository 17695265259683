import { Identification } from "easyclick-common/model/identification-model";
import axios from "../helper/axios";

/**
 *
 */
export default abstract class IdentificationService<T extends Identification> {
    private _baseUrl: string;

    constructor(baseUrl: string) {
        this._baseUrl = baseUrl;
    }

    delete(id: NonNullable<T["id"]>): Promise<boolean> {
        return axios.delete(this._baseUrl + "/" + id)
            .then((res) => res.data as boolean);
    }

    update(item: T): Promise<T> {
        return axios.put(this._baseUrl + "/" + item.id, item)
            .then((res) => res.data as T);
    }

    add(item: T): Promise<T> {
        return axios.post(this._baseUrl, item)
            .then((res) => res.data as T);
    }

    getList(): Promise<T[]> {
        return axios.get(this._baseUrl)
            .then((res) => res.data as T[]);
    }

    getItem(id: NonNullable<T["id"]>): Promise<T> {
        return axios.get<T>(this._baseUrl + "/" + id)
            .then((res) => res.data as T);
    }
}
