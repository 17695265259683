
import { createNewTicket, Ticket, TicketList } from "easyclick-common/model/ticket-model";
import DateTimeComponent from "./base/datetime-component.vue";
import DeleteDialogComponent from "./delete-dialog-component.vue";
import TicketEditComponent from "./ticket-edit-component.vue";
import moment from "moment";
import Vue from "vue";
import TicketService from "../services/ticket-service";
import TableComponent, { HeaderType } from "./base/table-component.vue";
import { UiTicketDetail } from "../model/ticketdetail";
import { appName } from "easyclick-common/config";
import { ComposedError } from "../helper/axios";
import LevelmatrixModule from "../store/modules/levelmatrix-module";

type DataType = {
    tickets: TicketList,
    isLoading: boolean,
    startTime: Date,
    endTime: Date,
    dialog: boolean,
    dialogDelete: boolean,
    editedItem?: Ticket,
    appName: string,
};

type MethodType = {
    editItem: (item: Ticket) => void,
    deleteItem: (item: Ticket) => void,
    deleteItemConfirm: () => void,
    updateItem: (item: Ticket) => void,
    close: () => void,
    closeDelete: () => void,
    newEditItem: () => void,
    executeLoad: () => void,
    levelMatrixLoaded: () => void,
};

type PropsType = {
    isAdmin: boolean,
};

type ComputedType = { headers: HeaderType[], };

/**
 *
 * @param ms
 */
function convertMS(ms: number) {
    var m, s;
    s = Math.floor(ms / 1000);
    m = Math.floor(s / 60);
    s = s % 60;
    m = m % 60;
    return (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s);
}

export default Vue.extend<DataType, MethodType, ComputedType, PropsType>({
    name: "TicketTableComponent",
    components: {
        tableComponent: TableComponent,
        dateTimeComponent: DateTimeComponent,
        ticketEditComponent: TicketEditComponent,
        deleteDialogComponent: DeleteDialogComponent,
    },
    props: {
        isAdmin: { type: Boolean, default: false }
    },
    data() {
        return {
            isLoading: false,
            tickets: [] as TicketList,
            startTime: moment().subtract(this.isAdmin ? 1 : 2, "month").toDate(),
            endTime: new Date(),
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            editedItem: undefined,
            appName: appName,
        };
    },
    computed: {
        headers() {
            return [
                { text: this.$i18n.t("site.general.actions"), value: "actions", sortable: false },
                { text: this.$i18n.t("object.ticket.useForReporting"), value: "useForReporting", type: "icon", formatter: (x: boolean) => x ? "mdi-check" : "mdi-close", sortable: false },
                { text: this.$i18n.t("object.ticketDetail.startTime"), value: "start", formatter: (x?: Date) => x ? moment(x).format("L LTS") : "" },
                (!this.isAdmin ? undefined : {
                    text: this.$i18n.t("object.ticket.username"),
                    align: "start",
                    sortable: true,
                    value: "userName",
                }),
                { text: this.$i18n.t("object.ticket.customField1short"), value: "customField1" },
                { text: this.$i18n.t("object.ticket.customField2short"), value: "customField2" },
                { text: this.$i18n.t("object.ticket.customField3short"), value: "customField3" },
                { text: this.$i18n.t("object.ticket.comments"), value: "comment" },
                { text: this.$i18n.t("object.ticket.steps"), value: "stepCount" },
                { text: this.$i18n.t("object.ticket.activeDuration"), value: "activeMSecs", formatter: (x?: number) => x ? convertMS(x) : "" },// TODO: format as time in seconds e.g. "2:59" for 179 seconds
            ].filter((x) => x) as HeaderType[];
        },
    },
    mounted() {
        this.executeLoad();
    },
    created() {
        this.levelMatrixLoaded();
    },
    methods: {
        levelMatrixLoaded() {
            return LevelmatrixModule.levelMatrixList.length > 0;
        },
        editItem(item: Ticket) {
            if (item.id) {
                TicketService.getItem(item.id)
                    .then((res) => {
                        item = Object.assign({}, res);
                        item.ticketDetails?.forEach((detail) => detail.levelMatrix = LevelmatrixModule.levelMatrixList.find((matrixEl) => matrixEl.id === detail.levelMatrix?.id));
                        this.editedItem = { ...item };

                        this.dialog = true;
                        return;
                    })
                    .catch((err: ComposedError) => {
                        Vue.$toast.error(err.message ?? "Error");
                    });
            }
        },
        close() {
            this.editedItem = undefined;
            this.dialog = false;
        },
        deleteItem(item: Ticket) {
            this.editedItem = item;
            this.dialogDelete = true;
        },
        updateItem(item: Ticket) {
            if (this.editedItem?.id) {
                var index = this.tickets.findIndex((x) => x.id == this.editedItem?.id);
                if (index > -1) {
                    this.tickets.splice(index, 1, item);
                }
            } else {
                this.tickets.unshift(item);
            }
        },
        deleteItemConfirm() {
            var index = this.tickets.findIndex((x) => x.id === this.editedItem?.id);
            if (index >= 0 && this.editedItem?.id) {
                return TicketService.delete(this.editedItem.id)
                    .then(() => {
                        this.tickets.splice(index, 1);
                        return this.closeDelete();
                    });

            }
        },
        closeDelete() {
            this.dialogDelete = false;
            this.editedItem = undefined;
        },
        newEditItem() {
            this.editedItem = createNewTicket(new UiTicketDetail());
            this.dialog = true;
        },
        executeLoad() {
            this.isLoading = true;
            TicketService.getTicketList(this.startTime, this.endTime, this.isAdmin)
                .then((list) => {
                    this.tickets.replace(list);
                    this.isLoading = false;
                    return;
                })
                .catch((err: ComposedError) => {
                    Vue.$toast.error(err.message ?? "Error");
                });
        }
    }
});
