

import { EnumErrorCode } from "easyclick-common/error";
import Vue from "vue";
import { PropValidator } from "vue/types/options";
import { getSpecialLangTextForErrorCode } from "../../helper/error";
import { i18n } from "../../plugins/i18n";
import UserModule from "../../store/modules/user-module";

export type LoginUser = {
    loginName: string,
    password: string,
};

export type LoginUserFunction = (user: LoginUser) => Promise<unknown>;

export default Vue.extend({
    name: "LoginFormComponent",
    props: {
        login: { type: Object } as PropValidator<LoginUserFunction>,
        isLoggedIn: Boolean,
    },
    data: () => ({
        valid: false,
        isPwdVisible: false,
        password: "",
        passwordRules: () => [
            (v: string) => !!v || i18n.t("site.general.passwordRequired"),
        ],
        errorText: "",
        passwordLabel: () => i18n.t("site.component.login.passwordLabel"),
        loginNameLabel: () => i18n.t("site.component.login.loginLabel"),
        loginName: "",
        loginNameRules: () => [
            (v: string) => !!v || i18n.t("site.component.login.loginRequired"),
            (v: string) => /^[0-9]*$/.test(v) || i18n.t("site.component.login.loginOnlyNumbers"),
        ],
        isCapsLockActive: false,
    }),
    methods: {
        submit() {
            this.errorText = "";
            UserModule.loginUser({
                loginName: this.loginName,
                password: this.password,
            })
                .then(() => this.$emit("submit", undefined))
                .catch((err) => {
                    if (err.errorCode() === EnumErrorCode.LOGIN_METHOD_NOT_AVAILABLE) {
                        this.errorText = i18n.t("site.component.login.wrongAuthMethod").toString();
                    } else if (err.errorCode() !== undefined) {
                        this.errorText = getSpecialLangTextForErrorCode(err.errorCode()).toString();
                    }
                    else {
                        this.errorText = i18n.t("site.component.login.loginError").toString();
                    }
                    this.$toast.error(this.errorText);
                    this.$emit("error");
                });
        },
        reset() {
            this.loginName = "";
            this.password = "";
            this.isPwdVisible = false;
        },
    },
});
