import store from "../index";
import { Module, VuexModule, getModule, Mutation, Action } from "vuex-module-decorators";
import type { UserLevelMatrix, UserMatrixTree } from "easyclick-common/model/levelmatrix-model";
import { arrayToTree, nodeToPathString, TreeNodeEasyClick, TreeNodePathList } from "../../helper/treetools";
import type { ArrayToTreeRes } from "../../helper/treetools";
import { localOpenedNodes } from "../../helper/local-storage";
import { UserLevelVal } from "easyclick-common/model/levelval-model";
import { CalcLevelMatrixList, EasyCalcLevelMatrix } from "easyclick-common/model/calclevelmatrix-model";
import { catHelper } from "../../helper/logging";

export type TreeNodeUserEasyClickList = TreeNodeUserEasyClick[];
export type TreeNodeUserEasyClick = TreeNodeEasyClick<UserLevelVal, EasyCalcLevelMatrix<UserLevelMatrix, UserLevelVal>>;

/**
 *
 */
@Module({
    name: "levelMatrix",
    store: store,
    namespaced: true,
    dynamic: true,
})
class LevelMatrixModule extends VuexModule {
    levelMatrixList: CalcLevelMatrixList<EasyCalcLevelMatrix<UserLevelMatrix, UserLevelVal>, UserLevelMatrix, UserLevelVal> = [];
    levelMatrixTree: TreeNodeUserEasyClickList = [];
    defaultPreOpenedNodesVar: TreeNodeUserEasyClickList = [];
    userOpenedNodesVar: TreeNodeUserEasyClickList = [];

    get openedNodes(): TreeNodeUserEasyClickList {
        //catHelper.debug("openedNodes");

        //if (this.userOpenedNodesVar.length > 0) {
        return this.userOpenedNodesVar;
        //}

        //if (this.defaultPreOpenedNodesVar) {
        //    return this.defaultPreOpenedNodesVar;
        //}

        //return [];
    }

    @Action
    sockettree(tree: UserMatrixTree) {
        const loadUserOpenedVar = JSON.parse(localStorage.getItem(localOpenedNodes) ?? "[]") as TreeNodePathList;
        this.context.commit("setLevelMatrixList", tree);
        this.context.commit("setLevelMatrixTree", arrayToTree(this.levelMatrixList, tree.levelValList, loadUserOpenedVar));
    }

    @Action({ commit: "setUserOpenedNodes" })
    actionSetUserOpenedNodes(userOpenedNodes: TreeNodeUserEasyClickList) {
        return userOpenedNodes;
    }

    @Mutation
    setLevelMatrixList(matrixTree: UserMatrixTree) {
        this.levelMatrixList = matrixTree.levelMatrixList.map((x) => new EasyCalcLevelMatrix(x, matrixTree.levelValList));
    }

    @Mutation
    setLevelMatrixTree(levelMatrixNodes: ArrayToTreeRes<TreeNodeUserEasyClick>) {
        this.levelMatrixTree.replace(levelMatrixNodes.treeNodes);
        this.defaultPreOpenedNodesVar.replace(levelMatrixNodes.preOpenedDefaultNodes);
        this.userOpenedNodesVar.replace(levelMatrixNodes.preOpenedUserNodes);
    }

    @Mutation
    resetUserOpenedNodesToDefault() {
        this.userOpenedNodesVar.splice(0);
        this.userOpenedNodesVar.push(...this.defaultPreOpenedNodesVar);
        //this.userOpenedNodesVar.replace(this.defaultPreOpenedNodesVar);
    }

    @Mutation
    closeAllOpenedNodes() {
        this.userOpenedNodesVar.replace([]);
    }

    @Mutation
    addUserOpenedNodes(userOpenedNodes: TreeNodeUserEasyClickList) {
        userOpenedNodes.forEach((e) => {
            if (this.userOpenedNodesVar.indexOf(e) < 0)
                this.userOpenedNodesVar.push(e);
        });
    }

    @Mutation
    setUserOpenedNodes(userOpenedNodes: TreeNodeUserEasyClickList) {
        this.userOpenedNodesVar.replace(userOpenedNodes);
    }

    @Mutation
    saveOpenedNodes(newOpenedNodes: TreeNodeUserEasyClickList) {
        //catHelper.debug("saveOpenedNodes");
        //if (newOpenedNodes.length > 0) {
        const saveString = JSON.stringify(newOpenedNodes.map((x) => nodeToPathString(x)));
        catHelper.debug("saveOpenedNodes value " + saveString);

        localStorage.setItem(localOpenedNodes, saveString); // in localstorage
        // } else {
        //catHelper.debug("saveOpenedNodes not saved, length == 0");
        // }
    }

}

export default getModule(LevelMatrixModule);
